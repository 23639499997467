import { TReactEditor, insertNodes } from "@udecode/plate-core";
import { ELEMENT_ORNAMENTAL_BREAK } from "../createOrnamentalBreakPlugin";
import { BaseEditor, BaseElement, Editor } from "slate";
import { ELEMENT_PARAGRAPH, isElement } from "@udecode/plate";
import { NodeType } from "../../types";

export const insertOrnamentalBreak = (
  editor: TReactEditor,
): void => {
  const belowNode = Editor.next(editor as BaseEditor, {
    at: editor.selection || undefined,
  });

  const isBelowNodeOB = belowNode && (belowNode[0] as (BaseElement | BaseEditor) & NodeType).type === ELEMENT_ORNAMENTAL_BREAK;
  
  if(belowNode && !isBelowNodeOB) {
    insertNodes(
      editor,
      {
        type: ELEMENT_ORNAMENTAL_BREAK,
        children: [{ text: "" }],
      },
      {
        at: editor.selection || undefined,
      }
    );
  } else {
    insertNodes(
      editor,[
      {
        type: ELEMENT_ORNAMENTAL_BREAK,
        children: [{ text: "" }],
      },
      {
        type: ELEMENT_PARAGRAPH,
        children: [{ text: "" }],
      },],
      {
        at: editor.selection || undefined,
      }
    );
  }
 
};