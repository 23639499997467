import React, { useCallback } from "react";
import {
  findNode,
  getEndPoint,
  isElement,
  PlateEditor,
  removeNodes,
  setSelection,
  splitNodes,
  ToolbarButton,
  ToolbarButtonProps,
  unwrapNodes,
  usePlateEditorState,
  withoutNormalizing,
} from "@udecode/plate";
import { CURRENT_COLOR, TooltipIcon } from "../../../partials";
import {
  FEATURE_SPLIT_CHAPTER,
  isRangeSelected,
  getEditorNodesCount,
  getMidPointOfSplit
} from "../";
import { MyEditor, MySceneElement } from "../../../config/typescript";
import useRootStore from "../../../../../store/useRootStore";
import { allowFeatureForTextMessages } from "../../text-message/config";
import { allowFeatureForCalloutBoxes } from "../../callout-box/config";
import { SplitChapterIcon } from "../../../../../content/icons";
import { findAllNodesInDocByType } from "../../../../../utils/slate";
import { BaseEditor, Location } from "slate";
import { ELEMENT_ORNAMENTAL_BREAK, ELEMENT_SCENE } from "../../ornamental-break";
import { NodeType } from "../../types";
import { initBody } from "../../../../../utils/initials";

export const SplitChapterToolbarButton = (_: Omit<ToolbarButtonProps, "icon">) => {
  const editor = usePlateEditorState() as MyEditor;
  const { currentScene } = useRootStore().chapterStore;
  const enabled = allowFeatureForTextMessages(editor as PlateEditor) && allowFeatureForCalloutBoxes(editor as PlateEditor) && !currentScene;
  const { book, getCurrentStoredBook, getChapterById, saveChapterBody: saveChapterBodyToLocal, addNewChapter, getChapterMatterById } = useRootStore().bookStore;
  const { chapterMeta } = useRootStore().chapterStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const refreshPDFCache = useCallback(async () => {
    const { frontMatterIds, chapterIds } =
      getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds];
    const chapterData = await getChapterById(allChapterIds);
    const chapterCacheData = chapterData.map(
      ({ _id, type, startOn }) =>
        ({
          chapterId: _id,
          chapterType: type,
          startOn,
        } as IPDFCacheStore.ChapterCacheMetaData)
    );
    refreshCache(book._id, "chapter-add", {
      "chapter-add": { chapters: chapterCacheData },
    });
  }, [book, getCurrentStoredBook, refreshCache]);

  return (
    // <div className={sceneIndex !== null ? "toolbar-button-disable" : ""}>
    <div>
      <TooltipIcon title={"Split chapter"}>
        <div className={`${enabled ? "" : "plate-disabled"}`}>
          <ToolbarButton
            type={FEATURE_SPLIT_CHAPTER}
            icon={<SplitChapterIcon color={CURRENT_COLOR} />}
            onMouseDown={async () => {
              if (
                getEditorNodesCount(editor) === 0 ||
                isRangeSelected(editor)
              ) {
                return;
              }

              // Determine if we're splitting a scene by checking if the target node is a scene element
              const at = editor.selection as Location;
              const targetNode = findNode(editor, {
                at,
                match: { type: ELEMENT_SCENE },
              });
              const isSplittingAScene = (targetNode && (targetNode[0] as NodeType).type === ELEMENT_SCENE) ?? false;

              let pushToNextChapter;

              // Perform split node operations without normalization to avoid conflicts
              withoutNormalizing(editor, () => {
                /**
                 * If splitting a scene, unwrap all existing scene nodes
                 * Normalizer will take care of rewrapping, if needed
                */
                if (isSplittingAScene) {
                  const sceneNodes = findAllNodesInDocByType<MySceneElement>(
                    editor as BaseEditor,
                    ELEMENT_SCENE
                  );

                  for (const [index, sceneNode] of sceneNodes.entries()) {
                    unwrapNodes(editor, {
                      at: sceneNode[1] as Location,
                      match: (node) =>
                        isElement(node) && node.type === ELEMENT_SCENE,
                    });
                  }
                }

                // If the cursor is between a text for example, the content after the cursor is pushed into a different node.
                splitNodes(editor);

                const midPoint = getMidPointOfSplit(editor);
                const endPoint = getEndPoint(editor, []);

                // Select the stuff that needs to be moved to next chapter
                setSelection(editor, {
                  anchor: midPoint.anchor,
                  focus: endPoint,
                });

                const editorChildren = editor.children;

                pushToNextChapter = editorChildren.slice(
                  midPoint.index,
                  editorChildren.length
                );

                // Maintain the proper scene structure
                // Prepend initial body if first node is an ornamental break
                if (isSplittingAScene && pushToNextChapter[0].type === ELEMENT_ORNAMENTAL_BREAK) {
                  pushToNextChapter.unshift(...initBody);
                }

                // Append initial body if last node is an ornamental break
                if (isSplittingAScene && pushToNextChapter[pushToNextChapter.length - 1].type === ELEMENT_ORNAMENTAL_BREAK) {
                  pushToNextChapter.push(...initBody);
                }

                // Remove selected nodes from current chapter as selected nodes will be moved to next chapter
                removeNodes(editor);
              });

              // TODO:BODY
              // saveChapterBodyUpdates(chapter._id, editor.children);

              await addNewChapter({
                section: getChapterMatterById(chapterMeta._id),
                type: chapterMeta.type,
                chapter: {
                  title: chapterMeta.title + " (SPLIT)",
                  parentChapterId: chapterMeta.parentChapterId,
                },
                body: pushToNextChapter,
              });

              await refreshPDFCache();
            }}
          />
        </div>
      </TooltipIcon>
    </div>
  );
};
