import React from "react";
import { Row, Col, notification } from "antd";
import { PlateEditor, Value } from "@udecode/plate";
import { cloneDeep } from "lodash";

import useRootStore from "../../store/useRootStore";
import { Button, ButtonType, BackgroundColor } from "../Shared/Buttons/Button";
import { applySmartQuotes } from "../../utils/helper";
import { NotificationCloseIcon } from "../../content/icons";
import { replaceYChapterContent } from "../../utils/y";

interface ApplyQuotesProps {
  editor: PlateEditor<Value>;
}

const ApplyQuotes: React.FC<ApplyQuotesProps> = ({ editor }) => {
  const { 
    setChangeCount 
  } = useRootStore().bookStore;
  const { chapterMeta, setChapterBody } = useRootStore().chapterStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const applyQuotes = async () => {
    const body = applySmartQuotes(cloneDeep(editor.children));
    await replaceYChapterContent(chapterMeta._id, body);
    setChapterBody(body);

    setChangeCount();
    refreshCache(chapterMeta.bookId, "chapter-contents-change", {
      "chapter-contents-change": { chapterId: chapterMeta._id },
    });

    notification.open({
      message: null,
      placement: "topRight",
      icon: null,
      className: "smart-quotes-notification",
      closeIcon: <></>,
      description: (
        <div>
          <Row>
            <Col span={22} flex="auto" className="info">
              <div className="title">Smart Quotes have been applied!</div>
              <div className="description">
                Smart Quotes have been applied to this chapter.
              </div>
            </Col>
            <Col span={2} className="destroy" onClick={() => notification.destroy()}>
              <NotificationCloseIcon />
            </Col>
          </Row>
        </div>
      ),
    });
  };

  return (
    <div className="setting-area-form">
      <Row>
        <h5 className="apply-quotes-heading">
          Are you sure you wish to apply smart quotes to your chapter?{" "}
        </h5>
        <br />
        <p className="apply-quotes-text">
          * This action will replace all existing quotation marks and
          apostrophes with curly quotes and apostrophes. <br />
          <br />
          * Curly quotes are applied using an advanced algorithm. No curly quote
          algorithm is perfect and some quotes may still need to be corrected on
          an individual basis.
          <br />
          <br />
          * After the curly quotes have been applied you can manually adjust
          quotation marks by using keyboard shortcuts.
          <br />
          <br />
        </p>
        <p className="apply-quotes-text-warning">
          This action is irreversible.
        </p>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <Button
            className="apply-quotes-btn"
            type={ButtonType.PRIMARY}
            backgroundColor={BackgroundColor.GREEN}
            onClick={() => applyQuotes()}
          >
            {" "}
            Apply{" "}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ApplyQuotes;
