import React, { useCallback, useEffect, useMemo } from "react";
import {
  useEventPlateId,
  usePlateEditorState,
} from "@udecode/plate-core";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";
import { insertOrnamentalBreak } from "../transforms/insertOrnamentalBreak";
import { allowFeatureForTextMessages } from "../../text-message/config";
import { allowFeatureForCalloutBoxes } from "../../callout-box/config";
import { allowFeatureForList } from "../../../config/lists";
import useRootStore from "../../../../../store/useRootStore";
import { debounce } from "lodash";

export const OrnamentalBreakToolbarButton = ({
  id,
  ...props
}: ToolbarButtonProps):JSX.Element => {
  const editor = usePlateEditorState(useEventPlateId(id));
  const { currentScene } = useRootStore().chapterStore;
  const enabled = allowFeatureForTextMessages(editor) && allowFeatureForCalloutBoxes(editor) && allowFeatureForList(editor) && !currentScene;

  // Create debounced insert method
  const debouncedInsert = useMemo(
    () => debounce((editorInstance) => {
      insertOrnamentalBreak(editorInstance);
    }, 500, { leading: true, trailing: false }),
    []
  );

  // Clean up the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedInsert.cancel();
    };
  }, [debouncedInsert]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    debouncedInsert(editor);
  }, [editor, debouncedInsert]);


  return (
    <div className={`${enabled ? "" : "plate-disabled"}`}>
      <ToolbarButton
        onMouseDown={handleMouseDown}
        {...props}
      />
    </div>
  );
};