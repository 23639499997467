import {
  Doc,
  encodeStateAsUpdate,
  encodeSnapshot,
  snapshot,
  applyUpdate,
  XmlText,
} from "yjs";
import { yTextToSlateElement, slateNodesToInsertDelta } from "@slate-yjs/core";
import { fromUint8Array, toUint8Array } from "js-base64";
import { sha1 } from "object-hash";
import { IDBInstace } from "@surge-global-engineering/y-indexeddb";

import { AtticusClient } from "../api/atticus.api";
import { getOnlineStatus } from "./hooks/isOffline";
import { MyRootBlock } from "../components/Plate/config/typescript";
import {
  GetPlateChapterBodybyIdResponse,
  YHTTPSyncReqPayload,
  SyncWithRemoteResponse,
} from "../types/sync";

/**
 * Sends the encoded client state as a single update message to the server
 * Receives updates that server has but the client doesn't along with the hash digest of the
 * server doc, after applying the updates retrieved from the document state, sent by the client
 * The hash digest of the server is used to calculate if the client was already up-to-date before
 * applying the updates from the server.
 * if the chapter is newly created chapter on client, this initializes the chapter on the server
 * @param chapterId chapter id
 * @param clientState Entire client state as a string
 * @returns {Uint8Array} Updates the server has but client doesn't
 * @returns {boolean} if client already up-to-date
 */
export const syncChapterWithRemote = async (
  chapterId: string,
  clientState: Uint8Array
): Promise<SyncWithRemoteResponse> => {
  const clientStateString = fromUint8Array(clientState);
  const payload: YHTTPSyncReqPayload = {
    chapterId,
    clientState: clientStateString,
  };
  const response = await AtticusClient.SyncDocument(payload);
  const serverHashDigest = response.serverHashDigest;
  const serverDiff = toUint8Array(response.serverDiff);
  return {
    serverDiff,
    serverHashDigest
  };
};

/**
 * Fetches chapter body updates in local DB in Uint8Array format and returns as an array of
 * Plate nodes. Optionally, can sync the local DB state with server to fetch changes to the chapter
 * by remote clients
 * @param chapterId chapter id
 * @param [syncWithRemote = false] Sync local and remote updates for chapter before returning chapter body
 * @returns {MyRootBlock[]} Chapter body content as an array of plate nodes
 * @returns {boolean} If client was already up-to-date, before syncing with the server (if syncWithRemote is set to true, otherwise defaults to false)
 */
export const getPlateChapterBodyForChapter = async (
  chapterId: string,
  syncWithRemote = false
): Promise<GetPlateChapterBodybyIdResponse> => {
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  const ydoc = new Doc();
  await idbInstance.syncUpdatesFromDBToDoc(ydoc);
  let isClientAlreadyInSync = false;
  if (syncWithRemote) {
    const isOnline = getOnlineStatus();
    if (isOnline) {
      const clientState = encodeStateAsUpdate(ydoc);
      const syncResponse = await syncChapterWithRemote(chapterId, clientState);
      isClientAlreadyInSync = isEmptyUpdate(syncResponse.serverHashDigest, clientState);
      await idbInstance.updateDB(syncResponse.serverDiff);
    } else {
      console.error("Unable to sync chapter updates: No internet connection");
    }
  }
  const contentShared = ydoc.get("content", XmlText) as XmlText;
  const chapterBodyAsNodes = yTextToSlateElement(contentShared).children;
  idbInstance.closeConnection();
  ydoc.destroy();
  return {
    chapterBody: chapterBodyAsNodes as unknown as MyRootBlock[],
    isClientAlreadyInSync: isClientAlreadyInSync,
  };
};

/**
 * Creates a new Y chapter in local db and syncs with the remote server if connected to
 * the internet
 * @param chapterId chapter id for the new chapter
 * @param chapterBody chapter body for the new chapter, in plate format
 */
export const initializeNewYChapter = async (
  chapterId: string,
  chapterBody: MyRootBlock[]
): Promise<void> => {
  const ydoc = new Doc();
  const delta = slateNodesToInsertDelta(chapterBody);
  const contentShared = ydoc.get("content", XmlText) as XmlText;
  contentShared.applyDelta(delta);
  const docStateAsUpdate = encodeStateAsUpdate(ydoc);
  ydoc.destroy();
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  await idbInstance.updateDB(docStateAsUpdate);
  idbInstance.closeConnection();
  const isOnline = getOnlineStatus();
  if (isOnline) {
    /** this initializes the new chapter on server */
    await syncChapterWithRemote(chapterId, docStateAsUpdate);
  }
};

/**
 * Replaces the Y chapter content in local db and syncs updates with the remote server if connected to
 * the internet
 * Warning: This method of replacing content can conflict with how yjs conventionally track updates
 * and therefore might affect conflict resolution.
 * @param chapterId chapter id
 * @param newChapterBody chapter body to replace the original chapter body, in plate format
 */
export const replaceYChapterContent = async (
  chapterId: string,
  newChapterBody: MyRootBlock[]
): Promise<void> => {
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  const ydoc = new Doc();
  await idbInstance.syncUpdatesFromDBToDoc(ydoc);
  const contentShared = ydoc.get("content", XmlText) as XmlText;
  //TODO: Do research on a more yjs friendly way of clearing a document.
  contentShared.delete(0, contentShared.length);
  const delta = slateNodesToInsertDelta(newChapterBody);
  contentShared.applyDelta(delta);
  const docStateAsUpdate = encodeStateAsUpdate(ydoc);
  ydoc.destroy();
  await idbInstance.updateDB(docStateAsUpdate);
  const isOnline = getOnlineStatus();
  if (isOnline) {
    const syncResponse = await syncChapterWithRemote(chapterId, docStateAsUpdate);
    await idbInstance.updateDB(syncResponse.serverDiff);
  }
  idbInstance.closeConnection();
};

/**
 * Adds new content to an existing chapter
 * @param chapterId chapter id to add new content
 * @param newContent the new chapter content to add, in plate format
 */
export const addYChapterContentToExistingChapter = async (
  chapterId: string,
  newContent: MyRootBlock[]
): Promise<void> => {
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  const ydoc = new Doc();
  await idbInstance.syncUpdatesFromDBToDoc(ydoc);
  const contentShared = ydoc.get("content", XmlText) as XmlText;
  const newContentDelta = slateNodesToInsertDelta(newContent);
  
  // Get the current length of the XmlText content
  const existingLength = contentShared.length;

  // Prepend a retain operation to position the new content at the end
  const delta = [{ retain: existingLength }, ...newContentDelta];

  contentShared.applyDelta(delta);
  const docStateAsUpdate = encodeStateAsUpdate(ydoc);
  ydoc.destroy();
  await idbInstance.updateDB(docStateAsUpdate);
  const isOnline = getOnlineStatus();
  if (isOnline) {
    const syncResponse = await syncChapterWithRemote(chapterId, docStateAsUpdate);
    await idbInstance.updateDB(syncResponse.serverDiff);
  }
  idbInstance.closeConnection();
};

/**
 * Compares the hashes of the client doc before applying updates from the server, and server doc after
 * applying the updates from the client, to determine if client doc was already up-to-date and the diff
 * sent by the server is empty
 * @param serverHashDigest
 * @param clientStateBeforeUpdate
 * @returns {boolean} Is diff sent by server empty
 */
export const isEmptyUpdate = (
  serverHashDigest: string,
  clientStateBeforeUpdate: Uint8Array
): boolean => {
  const clientDocBeforeUpdate = new Doc();
  applyUpdate(clientDocBeforeUpdate, clientStateBeforeUpdate);
  const clientSnapshotBeforeUpdate = encodeSnapshot(
    snapshot(clientDocBeforeUpdate)
  );
  clientDocBeforeUpdate.destroy();
  const clientDigestBeforeUpdate = sha1(clientSnapshotBeforeUpdate);
  return clientDigestBeforeUpdate === serverHashDigest;
};

/**
 * Saves updates (from a ydoc) to the local db and syncs with the remote server if connected to the internet
 * @param chapterDoc ydoc containing the updates
 * @param chapterId chapterId to save the updates
 */
export const saveAndSyncYChapterContent = async (
  chapterId: string,
  chapterDoc: Doc
): Promise<void> => {
  const docStateAsUpdate = encodeStateAsUpdate(chapterDoc);
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  await idbInstance.updateDB(docStateAsUpdate);
  const isOnline = getOnlineStatus();
  if (isOnline) {
    const syncResponse = await syncChapterWithRemote(chapterId, docStateAsUpdate);
    applyUpdate(chapterDoc, syncResponse.serverDiff);
    await idbInstance.updateDB(syncResponse.serverDiff);
  }
  idbInstance.closeConnection();
};