import { findNode, PlateEditor, Value } from "@udecode/plate";
import { NodeType } from "../plugins/types";

const DISABLED_TYPES = ["ul", "ol"];

export const allowFeatureForList = (editor: PlateEditor<Value>): boolean => {
  const focusPoint = editor.selection?.focus;

  let enabled = true;

  if (typeof focusPoint !== "undefined") {
    enabled = !findNode(editor, {
      at: editor.selection?.focus.path,
      match: (node) => DISABLED_TYPES.includes((node as NodeType).type),
      mode: "highest",
    });
  }

  return enabled;
};
