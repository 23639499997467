import { generate as generateRandomString } from "randomstring";

import { CHAPTER_TITLE_HIGHEST_SCALE } from "../../utils/chapter";
import { copyrightTemplates, initBody } from "../../utils/initials";

export const getInitBookContent = (
  bookId: string,
  bodyChapterId: string
): IBookStore.InitialBookContent => {
  const body: IChapterStore.Chapter = {
    _id: bodyChapterId,
    bookId: bookId,
    title: "",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    type: "chapter",
    image: "",
    startOn: "right",
    subtitle: "",
    index: 0,
    children: initBody,
  };
  const title: IChapterStore.ChapterMeta = {
    _id: generateRandomString(16),
    bookId: bookId,
    title: "Title Page",
    type: "title",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    subtitle: "",
    index: 0,
    image: "",
    startOn: "right",
  };
  const copyright: IChapterStore.Chapter = {
    _id: generateRandomString(16),
    bookId: bookId,
    title: "Copyright",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    type: "copyrights",
    subtitle: "",
    image: "",
    index: 0,
    startOn: "left",
    children: copyrightTemplates[0].children,
  };
  const toc: IChapterStore.ChapterMeta = {
    _id: generateRandomString(16),
    bookId: bookId,
    title: "Contents",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    type: "toc",
    subtitle: "",
    image: "",
    index: 0,
    startOn: "right",
  };
  const initBookContent = {
    body,
    title,
    copyright,
    toc,
  };
  return initBookContent;
};
