import React, { useEffect, useState, useMemo } from "react";
import {
  useLocation,
  useParams,
  generatePath,
  matchPath,
  useHistory
} from "react-router-dom";import { observer } from "mobx-react";
import { UsedFont, themePropsToCss } from "@surge-global-engineering/css-generator";

import { Modal } from "antd";

import useRootStore from "../../store/useRootStore";
import { db } from "../../db/bookDb";
import Toolbar from "./Toolbar";
import { generateToc } from "../../utils/toc";
import {
  parseTitleCard,
  parseFullPageImage,
  parseTOC,
  parseChapter,
  parseEndNoteChapter,
  parseEPubNotIncluded,
} from "../../press/exportEpub/parsers";
import {
  isFrontMatterChapter,
  getAdjacentChapterIds,
  shouldApplyDropCaps,
  epubErrorModalContent,
  getPreviewerConfigByDevice,
  DeviceName,
  isIncludedInEpub,
} from "../../press/exportEpub/helpers";
import { PrintPreviewer } from "./print";
import { PdfChapterEndnotes, PdfEndnoteSubheading, PdfSlateEndnote } from "./print/types";
import { getChapterNumber } from "../../utils/chapter-numbering";
import { AtticusFontBaseUrl } from "../../utils/font";
import saveAs from "file-saver";

import "./index.scss";
import "./previewerStyles.scss";
import { GetThemeFromIDB, GetBookWithBody } from "../../utils/offline.book.helpers";
import { generate } from "randomstring";
import { useOnlineStatus } from "../../utils/hooks/isOffline";
import { getFontsForEpub } from "../../press/exportEpub/helpers/getFontsForEpub";
import { Button } from "../Shared/Buttons/Button";
import { VerticalLeftOutlinedIcon, VerticalRightOutlinedIcon } from "../../content/icons";
import { ScrollContainer } from "../Shared/Layouts";
import { getPlateChapterBodyForChapter } from "../../utils/y";
import {
  Text,
} from "slate";
import { MyRootBlock } from "../Plate/config/typescript";
import { TComment } from "../Plate";
import { exportEpubBook } from "../../press/exportEpub";
import { ThemeResponse } from "../../types/theme";

const previewerPrefix = "previewer";

interface PreviewerProps {
  isSidebar?: boolean;
}

const routes = [
  "/books/:bookId/:chapterId",
  "/books/:bookId/:chapterId/formatting"
];

const Previewer = observer((props:PreviewerProps ) => {
  const { isSidebar } = props;
  const { pathname } = useLocation();
  const { push } = useHistory();
  const pathPattern = routes.find((pattern) => matchPath(pathname, {
    path: pattern,
    exact: true
  }));
  
  const params = useParams();  
  const {
    book,
    setErrorBook,
    getChapterById,
    getAllEndNotesOfBook,
    getAllEndNotesByChapter,
  } = useRootStore().bookStore;
  const { chapterMeta, chapterBody } = useRootStore().chapterStore;
  const { getDevicePreview, activeTheme: theme, isThemeBuilderActive, confirmExitEdit, themeFormFields, getAndSetTheme, synced, allThemes: themes, } = useRootStore().themeStore;
  const { previewerConfigs, setPreviewerConfigs } = useRootStore().appStore;
  const { setPDFExproterOptions } = useRootStore().appStore;
  const { resetSelectedChapters } = useRootStore().sideMenuStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const [endnotes, setEndnotes] = useState<(PdfSlateEndnote | PdfEndnoteSubheading)[]>([]);
  const [endnotesByChapterTitle, setEndnotesByChapterTitle] = useState<(PdfChapterEndnotes)[]>([]);
  const [chapters, setChapters] = useState<IChapterStore.Chapter[]>([]);
  const [previewFontSize, setPreviewFontSize] = useState(12);
  const [previewFont, setPreviewFont] = useState("Palatino");
  const [epubExporting, setEpubExporting] = useState(false);
  const isOnline = useOnlineStatus();
  const [usedFonts, setUsedFonts] = useState<UsedFont[]>([]);

  const getBookChapters = async () => {
    const chaps = await getChapterById([...book.chapterIds, ...book.frontMatterIds]);
    setChapters(chaps);
  };

  useEffect(() => {
    getBookChapters();
  }, [chapterMeta]);

  useEffect(() => {
    if (book.themeId !== theme._id && themes.length>0){
      getAndSetTheme(book.themeId);
    }
    refreshCache(book._id, "theme-change");
  }, [synced]);

  useEffect(() => {   
    let unmounted = false;
    (async () => {
      const {usedFonts} = await getFontsForEpub(theme.properties, db.userFavouriteFonts);
      if(usedFonts) setUsedFonts(usedFonts);
      if (chapterMeta.type !== "endnotes") {
        return;
      }
      if (
        theme?.properties.notesMode !== "END_OF_BOOK" &&
        theme?.properties.ePubNotesMode !== "END_OF_BOOK"
      ) {
        return;
      }
      if (theme.properties.ePubEndnotesChapterSettings.categorizeByTitle) {
        await getAllEndNotesByChapter().then((note) => {
          if (!unmounted) setEndnotesByChapterTitle(note);
        });
      } else {
        await getAllEndNotesOfBook().then((note) => {
          if (!unmounted) setEndnotes(note);
        });
      }
    })();
    return () => {
      unmounted = true;
    };
}, [chapterMeta, theme, themeFormFields]);

  /** export epub, pdf configs */
  const removeErrorBooks = (bookId: string) => {
    db.failedChapters.where("_bookId").anyOf(bookId).delete();
    db.failedBooks.where("_bookId").anyOf(bookId).delete();
  };

  const exportEpub = async () => {
    setEpubExporting(true);

    const theme = await GetThemeFromIDB(book.themeId);
    const {properties: themeStyleProps} = theme as ThemeResponse;
    const {fontFiles, usedFonts} = await getFontsForEpub(themeStyleProps, db.userFavouriteFonts);
    const fullBook = await GetBookWithBody(book._id);

    let bookEndnotes: (PdfSlateEndnote | PdfEndnoteSubheading)[] | PdfChapterEndnotes[] = [];
    if(theme?.properties.ePubNotesMode === "END_OF_BOOK"){
      bookEndnotes = theme.properties.ePubEndnotesChapterSettings.categorizeByTitle ? await getAllEndNotesByChapter(): await getAllEndNotesOfBook();
    }
    if(fullBook && theme) {
      try {
        const epub = await exportEpubBook(fullBook, theme, bookEndnotes, fontFiles, usedFonts);
        saveAs(new Blob([epub]), `${book.title}-${generate(10)}.epub`);
        removeErrorBooks(book._id);
      } catch (e) {
        console.error(e);
        setErrorBook(book._id);
        Modal.confirm(epubErrorModalContent);
      }
    }
    setEpubExporting(false);
  };

  const exportLocalPdf = () => {
    setPDFExproterOptions(book._id, book.title);
  };

  const searchForMark = (nodes: MyRootBlock[]) => {
    let found = false;

    const searchNodes = (nodes) => {
      for (const node of nodes) {
        // If the node is a text node and contains marks
        if (Text.isText(node)) {
          if (node["trackChanges"]) {
            found = true;
            return;
          }
          if (node["comments"]) {
            const entries = Object.values(node["comments"]);
            const hasIsResolved = (entries[0] as TComment).isResolved;
            if (!hasIsResolved) {
              found = true;
              return;
            }
          }
        }
        if (node.children) {
          searchNodes(node.children);
        }
      }
    };

    searchNodes(nodes);
    return found;
  };

  const checkUnresolved = async () => {
    let isUnresolved = false;

    const promises = book.chapterIds.map(async (chapterId) => {
      try {
        const chapterChildren = await getPlateChapterBodyForChapter(chapterId);
        const findUnresolved = searchForMark(chapterChildren.chapterBody);
        if (findUnresolved) {
          isUnresolved = true;
        }
      } catch (error) {
        console.error("Error fetching chapter children:", error);
      }
    });

    await Promise.all(promises);
    return isUnresolved;
  };

const confirmUnresolvedChanges = (callback: () => void) => {
  return new Promise<boolean>((resolve) => {
    Modal.confirm({ 
      icon: null,
      title: <h2 className="section-heading">Unresolved Changes Detected</h2>,
      content: "There are unresolved Track Changes and Comments in some chapters. Do you still want to proceed?",
      centered: true,
      onOk: () => {
        callback();
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
      },
      
      cancelText: "No",
      cancelButtonProps: {
        className: "btn-a",
        style: {
          flex: 1,
        },
      },
      okText: "Yes",
      okButtonProps: {
        type: "primary",
        style: {
          flex: 1,
        },
      },
    });
  });
};

  const handleExitEdit = async (callback: () => void) => {
    if (isThemeBuilderActive) {
      confirmExitEdit(
        callback,
        () => undefined,
        {
          title: "You cannot export unsaved changes",
          ok: "Continue working",
          cancel: "Discard and Export"
        }
      );
      return;
    }

    const unresolved = await checkUnresolved();
    if (unresolved) {
      confirmUnresolvedChanges(callback);
    } else {
      callback();
    }
  };

  /** epub previewer body and styling generators */
  const chapterNumber = useMemo(() => {
    return  getChapterNumber(chapterMeta,book);
  }, [chapterMeta, book]);

  const innerHtmlForChapter = useMemo(() => {
    if(theme && !isIncludedInEpub(chapterMeta.includeIn)) {
      return parseEPubNotIncluded();
    }

    if (theme && isIncludedInEpub(chapterMeta.includeIn)) {
      if (chapterMeta.type === "title") return parseTitleCard(book, chapterMeta);
      if (chapterMeta.type === "image") {
        //TODO:Verify
        return parseFullPageImage({ ...chapterMeta, children: chapterBody });
      }
      if (chapterMeta.type === "toc") {
        //TODO:Verify
        return parseTOC(
          generateToc(book, chapters, "ebook", chapterMeta),
          chapterMeta.title,
        );
      }
      if (chapterMeta.type === "part") {
        return parseEPubNotIncluded();
      }
      if (isFrontMatterChapter(chapterMeta.type)) {
        // TODO - Logic needs to update with chapter level configs
        // removes chapter header images for frontmatter
        //TODO:Verify
        return parseChapter(
          {
            ...chapterMeta,
            children: chapterBody,
          },
          theme?.properties,
          false,
          chapterNumber,
          previewerPrefix
        );
      }
      if(chapterMeta.type == "volume"){
        return parseEPubNotIncluded();
      }
      if (chapterMeta.type === "endnotes") {
        if (theme?.properties.ePubNotesMode === "END_OF_BOOK" && theme.properties.ePubEndnotesChapterSettings.categorizeByTitle ) {
          return parseEndNoteChapter(chapterMeta, endnotesByChapterTitle, theme.properties);
        }
        if(theme?.properties.ePubNotesMode === "END_OF_BOOK" && !theme.properties.ePubEndnotesChapterSettings.categorizeByTitle){
          return parseEndNoteChapter(chapterMeta, endnotes, theme.properties);
        }
        return parseEPubNotIncluded(); 
      }    
      // TODO:Verify
      return parseChapter(
        {
          ...chapterMeta,
          children: chapterBody,
        },
        theme.properties,
        false,
        chapterNumber,
        previewerPrefix
      );
    }
  }, [chapterMeta, chapters, theme, book, chapterBody, chapterNumber, endnotes, endnotesByChapterTitle]);


  const epubPreviewer = useMemo(() => {
    const shouldRenderInnerLevel = previewerConfigs.l3ClassName;
    if (innerHtmlForChapter) {
      return (
        <div className="previewer">
          <div className={`p-device ${previewerConfigs.l1ClassName}`}>
            <div
              className={`${previewerConfigs.l2ClassName}`}
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
              dangerouslySetInnerHTML={
                shouldRenderInnerLevel
                  ? undefined
                  : { __html: innerHtmlForChapter }
              }
            >
              {shouldRenderInnerLevel && (
                <div
                  className={previewerConfigs.l3ClassName}
                  dangerouslySetInnerHTML={{ __html: innerHtmlForChapter }}
                ></div>
              )}
            </div>
            <img
              width={previewerConfigs.width}
              src={previewerConfigs.deviceUrl}
            />
          </div>
        </div>
      );
    }
  }, [innerHtmlForChapter, previewerConfigs, previewFont, previewFontSize]);

  const styles = useMemo(() => {
    if (theme) {
      return themePropsToCss(theme, usedFonts, AtticusFontBaseUrl, true, previewerPrefix);
    }
  }, [theme]);

  /** pdf previewer configs */

  const {  prev, next } = useMemo(() => {
    return getAdjacentChapterIds(chapterMeta, book);
  }, [chapterMeta, book]);

  const chapterForPreviewer = useMemo(
    () => ({ ...chapterMeta, children: chapterBody }),
    [chapterMeta, chapterBody]
  );
  
  const handlePrevChapter = () => {
    if (prev) {
      push(generatePath(pathPattern || "", { ...params, chapterId: prev }));
      resetSelectedChapters();
    }
  };

  const handleNextChapter = () => {
    if (next) {
      push(generatePath(pathPattern || "", { ...params, chapterId: next }));
      resetSelectedChapters();
    }
  };

  /** toolbar configs */

  const onChangDevice = async (deviceName: DeviceName) => {
    if (deviceName === "print") {
      setPreviewerConfigs({
        deviceName,
      });
      return;
    }
    const deviceSpec = await getDevicePreview(deviceName);
    if (deviceSpec) {
      const configs = getPreviewerConfigByDevice(
        deviceSpec._deviceName as DeviceName
      );
      setPreviewerConfigs({
        ...configs,
        deviceUrl: deviceSpec.image,
        deviceName: deviceSpec._deviceName,
      });
    }
  };

  const toolbarFontSizeCallBack = (size: number) => setPreviewFontSize(size);

  const toolbarFontCallBack = (font: string) => setPreviewFont(font);

  return (
    <ScrollContainer>
      <div
        className={`previewer-wrapper full ${
          shouldApplyDropCaps(chapterMeta) ? "withDropcap" : ""
        } ${theme?._id}`}
      >
        <style>{styles}</style>
        <Toolbar
          device={previewerConfigs.deviceName}
          ondeviceselect={onChangDevice}
          previewFontSize={toolbarFontSizeCallBack}
          previewFont={toolbarFontCallBack}
        />
        {previewerConfigs.deviceName === "print" && theme && (
            <PrintPreviewer
              theme={theme}
              chapter={chapterForPreviewer}
              prev={prev}
              handlePrevChapter={handlePrevChapter}
              next={next}
              handleNextChapter={handleNextChapter}
              chapterNumber={chapterNumber}
            />
        )}
        {previewerConfigs.deviceName !== "print" && (
          <div className="epub-previewer-container">
            {epubPreviewer}
            <div className="previewer-actions">
              <div className="epub-chapter-navigation">
                <Button type="at-secondary" backgroundColor="green" className="previous-chapter" icon={<VerticalLeftOutlinedIcon />} onClick={handlePrevChapter} disabled={!prev}>
                  Chapter
                </Button>
                <Button type="at-secondary" backgroundColor="green" icon={<VerticalRightOutlinedIcon />} className="next-chapter reverse" onClick={handleNextChapter} disabled={!next}>
                  Chapter
                </Button>
              </div>
            </div>
          </div>
        )}

        {!isSidebar && 
          <div className="previewer-exports">
          <div className="export-buttons">
            <Button type="at-primary" backgroundColor="green" className="export-pdf" onClick={() => handleExitEdit(exportLocalPdf)}>
              Export pdf
            </Button>
            <Button type="at-primary" backgroundColor="green" className="export-epub" loading={epubExporting} onClick={() => handleExitEdit(exportEpub)} disabled={!isOnline}>
              Export ePub
            </Button>
          </div>
        </div>
        }
      </div>
    </ScrollContainer>
  );
});

export default Previewer;