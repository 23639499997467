import React, { useEffect } from "react";
import {
  createComponentAs,
  createElementAs,
  HTMLPropsAs,
  Value,
  findNodePath,
  PlateRenderElementProps,
  setNodes,
  unwrapNodes,
  useElementProps,
  useEventPlateId,
} from "@udecode/plate";
import { EditOutlined } from "@ant-design/icons";
import { Badge, Form, Popover } from "antd";
import { useState } from "react";
import { TabbedLinkComponent } from "./TabbledLinkComponent";
import { isUrl, validateInternalLink, validateLink } from "../helpers";
import { unwrapLink } from "../transforms";
import {
  LinkType,
  TLinkElement,
  MyEditor,
  useMyEditorRef,
  getMyEditor,
} from "../../../config/typescript";

//Store
import useRootStore from "../../../../../store/useRootStore";
import { isNullOrUndefined } from "../../../../../utils/strings";
import { AtticusClient } from "../../../../../api/atticus.api";
import { Button } from "../../../../Shared/Buttons/Button";
import { Modal } from "../../../../Shared/Modal";
import { can } from "../../../../casl/helpers";

type LinkProps = PlateRenderElementProps<Value, TLinkElement> &
  HTMLPropsAs<"a">;

export const useLink = (props: LinkProps): HTMLPropsAs<"a"> => {
  const _props = useElementProps<TLinkElement, "a">({
    ...props,
    elementToAttributes: (element) => ({
      href: element.url,
    }),
  });

  return {
    ..._props,
    // quick fix: hovering <a> with href loses the editor focus
    onMouseOver: (e) => {
      e.stopPropagation();
    },
  };
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const Link = createComponentAs<LinkProps>((props) => {
  const linkProps = useLink(props);
  const PlateEditor = useMyEditorRef();
  const id = useEventPlateId(props.id);
  const { element } = props;
  const editor = getMyEditor(PlateEditor) as MyEditor;
  const isFromEditLink = true;

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState<string>(props.element.url);
  const [type, setType] = useState<LinkType>(
    element.linkType ||
      (validateInternalLink(props.element.url) ? "internal-link" : "web-link")
  );
  const { showModal, setUpdateLinkUrl, dismissAllModals, newLinkUrl, nodeID } =
    useRootStore().editorModalStore;

  const [popoverVisible, setPopoverVisible] = useState(false);
  const onPopoverVisibilityChange = () => {
    setPopoverVisible(!popoverVisible);
  };

  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState("");

  const showModalAndHidePopover = () => {
    setOpen(true);
    setValue(element.url);
    setType(
      element.linkType ||
        (validateInternalLink(element.url) ? "internal-link" : "web-link")
    );
    setPopoverVisible(false);
  };

  const PopOverContent = () => {
    const editBtn = () => {
      showModalAndHidePopover();
      showModal("update_link");
      setUpdateLinkUrl(element.url, id);
    };

    const visitBtn = () => {
      setPopoverVisible(false);
      window.open(props.element.url, "_blank");
    };

    const updateLink = (url: string) => {
      const path = findNodePath(editor, element);
      if (nodeID === element.id) {
        setNodes(
          editor,
          {
            ...element,
            url,
          },
          { at: path }
        );
      }
      dismissAllModals();
    };

    const deleteLink = () => {
      const path = findNodePath(editor, element);
      if (nodeID === element.id) {
        unwrapNodes(editor, { at: path });
      }
      dismissAllModals();
    };

    const updateBtnOnClick = async () => {
      let booklinkerLink = "";
      setBtnLoading(true);

      if (type === "booklinker") {
        const res = await AtticusClient.CreateBooklinkerLink(value);
        if (res.success) {
          booklinkerLink = res.linkResult;
        } else {
          setError(res.responseStatus.message);
        }
      }

      if (
        (validateLink(value) && type === "web-link") ||
        (validateInternalLink(value) && type === "internal-link") ||
        (validateLink(booklinkerLink) && type === "booklinker")
      ) {
        setNodes(
          editor,
          {
            ...props.element,
            url: booklinkerLink || value,
            linkType: type,
          },
          { at: findNodePath(editor, props.element) }
        );
        setOpen(false);
      }

      setBtnLoading(false);
    };

    useEffect(() => {
      if (
        isNullOrUndefined(newLinkUrl.url) ||
        isNullOrUndefined(newLinkUrl.action)
      )
        return;

      switch (newLinkUrl.action) {
        case "update":
          updateLink(newLinkUrl.url ?? "");
          break;
        case "delete":
          deleteLink();
          break;
        default:
          break;
      }
      return;
    }, [newLinkUrl]);

    return (
      <div className="link-popover-content">
          <div className='link-popup-div'>
          <Button className="btn-green-edit" type='at-secondary' backgroundColor='green' onClick={editBtn}>
            Edit Link
          </Button>
          <Button className="btn-visit-link" type='at-ghost' backgroundColor='green' onClick={visitBtn}>
            Visit Link
          </Button>
        </div>
        <Modal
          open={open}
          title='Update Link'
          onCancel={() => {
            setOpen(false);
          }}
          destroyOnClose={true}
          leftBtn={{
            type: "at-secondary",
            className:"link-modal-btn",
            danger: true,
            block: true,
            onClick: () => {
              unwrapLink(editor, {
                at: findNodePath(editor, props.element),
              });
            },
            children: "Delete",
          }}
          rightBtn={{
            type: "at-primary",
            className:"link-modal-btn",
            backgroundColor: "green",
            disabled: !isUrl(value),
            onClick: updateBtnOnClick,
            loading: btnLoading,
            children:
              type === "booklinker" ? "Update Universal Link" : "Update",
          }}
        >
          <Form {...layout}>
            <TabbedLinkComponent
              type={type}
              value={value}
              error={error}
              setType={setType}
              setValue={setValue}
              isFromEditLink={isFromEditLink}
            />
          </Form>
        </Modal>
      </div>
    );
  };

  return (
    <>
      {createElementAs("a", linkProps)}
      <Popover
        placement="bottom"
        content={PopOverContent}
        getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
        trigger="hover"
        open={popoverVisible}
        onOpenChange={onPopoverVisibilityChange}
      >
        {can("view", "plugin-settings") &&
          <span
            className="link-edit-btn"
            style={{
              paddingLeft: "5px",
              cursor: "pointer",
              paddingRight: "10px",
            }}
          >
            <Badge
              count={
                <EditOutlined
                  onClick={() => {
                    showModalAndHidePopover();
                  }}
                />
              }
            />
          </span>}
      </Popover>
    </>
  );
});
