import {
  TPath,
  isElement,
  TReactEditor,
  removeNodes,
  TElement,
  findNodePath,
  unwrapNodes,
} from "@udecode/plate";
import { BaseEditor, Editor } from "slate";

import { MySceneElement } from "../../../config/typescript";
import { ELEMENT_SCENE } from "../createScenePlugin";
import { findAllNodesInDocByType } from "../../../../../utils/slate";

export const removeOrnamentalBreak = (
  element: TElement,
  editor: TReactEditor
): void => {
  const path = findNodePath(editor, element);
  removeNodes(editor, { at: path });

  Editor.withoutNormalizing(editor as BaseEditor, () => {
    const sceneNodes = findAllNodesInDocByType<MySceneElement>(
      editor as BaseEditor,
      ELEMENT_SCENE
    );

    /**
     * Unwrap all the scenes after removing an ornamental-break to ensure the proper scene structure
     * Normalizer will take care of the wrapping
     */
    for (const [index, sceneNode] of sceneNodes.entries()) {
      unwrapNodes(editor, {
        at: sceneNode[1],
        match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
      });
    }
  });
};
