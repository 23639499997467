import { findNode, PlateEditor, Value } from "@udecode/plate";
import { ELEMENT_TEXT_MESSAGES_GROUP, ELEMENT_TEXT_MESSAGE } from "./createTextMessagePlugin";
import { NodeType } from "../types";

const DISABLED_TYPES = [ELEMENT_TEXT_MESSAGES_GROUP, ELEMENT_TEXT_MESSAGE];

export const allowFeatureForTextMessages = (editor: PlateEditor<Value>): boolean => {
  const focusPoint = editor.selection?.focus;

  let enabled = true;

  if (typeof focusPoint !== "undefined") {
    enabled = !findNode(editor, {
      at: editor.selection?.focus.path,
      match: (node) => DISABLED_TYPES.includes((node as NodeType).type),
      mode: "highest",
    });
  }
  return enabled;
};
