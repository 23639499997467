import React from "react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import useRootStore from "../../store/useRootStore";


export const SyncUpdates: React.FC = observer(() => {
  const { socket } = useRootStore().bookSyncWebSocketStore;
  const { removeCollaboration } = useRootStore().collaborationStore;
  const { user } = useRootStore().authStore;

  const removeCollaborationHandler = async (id) => {
    await removeCollaboration(id);
  };

  useEffect(() => {
    if (socket) {
      if (user && user._id) {
        socket.send(
          JSON.stringify({
            action: "register-user",
            data: {
              userId: user._id
            }
          })
        );

        socket.onmessage = (evt) => {
          const data = JSON.parse(evt.data as string);
          // check if data exists
          if (data) {
            //check if type is notification
            if (data.type === "collaborator-removed-themselves" && data.data.collaboration_id) {
              removeCollaborationHandler(data.data.collaboration_id);
            }
          }
        };
      }
    }
  }, [socket, user]);

  useEffect(() => {
    // close the socket.
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  return (
    <></>
  );
});