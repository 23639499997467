export const notificationTypeToTextMap = {
  //plain
  1: "You have a new notification",

  //invited for book
  2: "<b>{1}</b> has added you as a <b>{3}</b> for the book <b>{2}</b>",

  //unassigned for book
  3: "You have been removed as a collaborator from the book <b>{1}</b>",

  //removed book
  4: "The book <b>{1}</b> is removed and no longer active for collaboration",

  //comments -- TODO: Make this dynamic 
  5: "You have a comment",

  //collaborator remove themselves from book
  6: "<b>{1}</b> has unassigned themselves from <b>{2}</b>"
};