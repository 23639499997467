import { ATNode } from "../components/Plate/config/typescript";
import { TrackChangesProperties } from "../components/Plate/plugins/track-changes";
import pluginProperties from "../components/Plate/plugins/track-changes/utils/activePlugins";

/* 
  Method to filter out the track-changes from the chapter-body for ePub/PDF previewers and exports
*/
const formatNodeChildren = (node) => {
    if (node.children) {
  
      node.children = node.children.map(leaf => {
  
        if (leaf.trackChanges?.operation === "format") {
  
          const { trackChanges, ...updatedLeaf } = leaf;
  
          // Remove the pluginProperties from updatedLeaf
          const cleanedLeaf = Object.keys(updatedLeaf).reduce((acc, key) => {
            if (!pluginProperties.includes(key)) {
              acc[key] = updatedLeaf[key]; // Only include keys not in pluginProperties
            }
            return acc;
          }, {} as Node);
  
          return {
            ...cleanedLeaf,
            ...trackChanges.formatting, // Apply the original formatting from trackChanges
          };
        }
        return leaf;
      });
  
      node.children = node.children.filter(child => {
        // Keep nodes without trackChanges or where the operation is "delete"
        return !(child.trackChanges) || ((child.trackChanges as TrackChangesProperties).operation === "delete");
      });
    }
    return node; 
  };
  
  export const filterTrackChanges = (chapterBody: ATNode) => {
    return chapterBody.map(node => formatNodeChildren(node));
  }; 
  