import {
  ELEMENT_PARAGRAPH,
  getLastNodeByLevel,
  insertElements,
  PlateEditor,
  setElements,
  setNodes,
} from "@udecode/plate";
import { Path } from "slate";
import { ELEMENT_SCENE } from "../../ornamental-break";

/**
 * Add a trailing block when the last node type is not `type` and when the
 * editor has .
 */
export const withTrailingBlock = <T extends PlateEditor>(
  editor: T
): PlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    const lastChild = getLastNodeByLevel(editor, 0);
    const lastChildNode = lastChild?.[0];

    if (
      lastChildNode?.type !== ELEMENT_PARAGRAPH &&
      lastChildNode?.type !== ELEMENT_SCENE
    ) {
      const at = lastChild ? Path.next(lastChild[1]) : [0];

      insertElements(
        editor,
        {
          type: ELEMENT_PARAGRAPH,
          children: [],
        },
        { at }
      );
    }

    normalizeNode(entry);
  };

  return editor;
};
