import React, { useEffect, useRef, useState } from "react";
import { Avatar, Col, Row, Input } from "antd";
import { observer } from "mobx-react";
import {
  PlateEditor,
  PortalBody,
  Value,
  setNodes,
  toDOMNode,
  useEditorRef,
} from "@udecode/plate";

import useRootStore from "../../../../../store/useRootStore";
import {
  BackgroundColor,
  Button,
  ButtonType,
} from "../../../../Shared/Buttons";

import { TComment } from "../types";
import {
  findCommentNodeById,
  getCommentNodesById,
  getCommentPosition,
} from "../queries";
import { handleCommentKeyDown } from "../utils/commentKeyHandlers";
import { unsetCommentNodesById } from "../utils";

const { TextArea } = Input;

const CommentsPopoverContent = observer(() => {
  const { activeCommentId, setActiveCommentId } =
    useRootStore().commentsStore;
  const { user } = useRootStore().authStore;

  const editor = useEditorRef();

  const [newValue, setNewValue] = useState<string>("");

  const submitComment = () => {

    if (activeCommentId && newValue !== "") {
      const newComment: TComment = {
        id: activeCommentId as string,
        value: newValue,
        createdAt: Date.now(),
        userId: user?._id as string,
      };

      const commentNodes = getCommentNodesById(editor, activeCommentId);

      commentNodes.forEach((nodeEntry) => {
        const [node, path] = nodeEntry;
        const nodeComments: Record<string, TComment> = node.comments || {};
        setNodes(
          editor,
          { comments: { ...nodeComments, [activeCommentId]: newComment } },
          { at: path }
        );
      });

      setNewValue("");
    }
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  // Focus the comment TextArea when the popover is opened
  useEffect(() => {
    if (activeCommentId && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [activeCommentId]);

  return (
    <>
      <Row className="user-info-row">
        <Col className="profile-picture-col">
          <Avatar
            size={32}
            src={user && user.profilePictureURL ? user.profilePictureURL : ""}
          />
        </Col>
        <Col className="username-col">
            {user?.firstName} {user?.lastName}
        </Col>
      </Row>

      <Row className="text-area-row">
        <Col span={24}>
          <TextArea
            className="comment-popover-textarea"
            ref={textAreaRef}
            rows={1}
            value={newValue}
            placeholder="Enter your comment"
            autoSize
            onKeyDown={(e)=>handleCommentKeyDown(e, submitComment)}
            onChange={(event) => {
              setNewValue(event.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="comment-btn-wrapper">
          <Button
            type={ButtonType.PRIMARY}
            backgroundColor={BackgroundColor.GREEN}
            onClick={submitComment}
            disabled={newValue === ""}
          >
            Comment
          </Button>
        </Col>
        <Col>
          <Button
            type={ButtonType.SECONDARY}
            backgroundColor={BackgroundColor.GREEN}
            onClick={() => setActiveCommentId(null)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
});

export const CommentsPopover = observer(() => {
  const { activeCommentId, setActiveCommentId, getCommentById } =
    useRootStore().commentsStore;
  const { chapterMeta } = useRootStore().chapterStore;

  const [loaded, setLoaded] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number }>({
    left: 0,
    top: 0,
  });
  const popoverRef = useRef<HTMLDivElement>(null);

  const editor = useEditorRef();
  const [node] = findCommentNodeById(editor, activeCommentId as string) ?? [];

  useEffect(() => {
    if (!node) return;

    const domNode = toDOMNode(editor, node);
    if (!domNode) return;

    const newPosition = getCommentPosition(editor, node, popoverRef);
    if (!newPosition) return;

    if (!activeCommentId) return;

    setPosition(newPosition);

    //dynamically change comment popover position when the comment popover height is increased
    const handleResize = (entries) => {
      for (const entry of entries) {
        if (entry.target === popoverRef.current) {
          const newPosition = getCommentPosition(editor, node, popoverRef);
          if(newPosition) setPosition(newPosition);
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (popoverRef.current) {
      resizeObserver.observe(popoverRef.current);
    }

    return () => {
      if (popoverRef.current) {
        resizeObserver.unobserve(popoverRef.current);
      }
    };

  }, [activeCommentId, editor, node]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        removeUnsubmittedComments(editor, activeCommentId);
        setActiveCommentId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  const removeUnsubmittedComments = (editor: PlateEditor<Value>, id: string) =>{
    unsetCommentNodesById(editor, { id });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const activeComment = getCommentById(activeCommentId);
      if(activeCommentId && !activeComment){
        removeUnsubmittedComments(editor, activeCommentId);
      }
    };

    //remove comment marks if user refreshes or closes the tab without submitting a comment
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeCommentId]);

  useEffect(() => {
    setActiveCommentId(null);
  }, [chapterMeta]);

  if (!loaded || !activeCommentId || !node) return null;
  const activeComment = getCommentById(activeCommentId);
  if (activeComment) return null;

  return (
    <PortalBody>
      <div
        className="comments-popover"
        style={{
          top: position.top,
          left: position.left,
        }}
        ref={popoverRef}
      >
        <CommentsPopoverContent />
      </div>
    </PortalBody>
  );
});
