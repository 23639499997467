import React, { FunctionComponent } from "react";

import { BookActionSection } from "../components/Books";
import { Row } from "antd";

export const Welcome: FunctionComponent = () => {
	return (
		<div className="welcome-screen">
			<div className="container">
				<div className="welcome-card">
					<h1>Welcome to Atticus!</h1>
					<p>To get started, upload an existing document or create a new project.</p>
					<div className="inner-s"></div>
					<Row>
						<BookActionSection />
					</Row>
				</div>
			</div>
		</div>
	);
};