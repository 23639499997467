import React, { useState, useEffect } from "react";
import { Popover, Skeleton, Divider, Avatar, notification as NotificationDialog } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import { observer } from "mobx-react";

import useWebsocket from "../../utils/hooks/useWebsocket";
import useRootStore from "../../store/useRootStore";

import { NotificationHeader, NotificationList } from "../../components/Notifications";
import { notificationTypeToTextMap } from "../../utils/notification";

import { useOnlineStatus } from "../../utils/hooks/isOffline";
import { NotificationNavButton } from "../notification";
import { useHistory } from "react-router-dom";
import { uniqBy } from "lodash";

export const NotificationContainer = observer(() => {
  const {
    notifications,
    hasMore,
    loadNotifications,
    appendNotification,
    loadUnreadCount,
    getNotification
  } = useRootStore().notificationStore;
  const {
    user,
    token
  } = useRootStore().authStore;
  const [notifPop, setNotifPop] = useState(false);
  const webSocketUrl = process.env.REACT_APP_SYNC_URL || "";
  const socket = useWebsocket(webSocketUrl ? `${webSocketUrl}?token=${token}` : "");
  const isOnline = useOnlineStatus();
  const { push } = useHistory(); 

  const handleNotifOpen = () => {
    setNotifPop(!notifPop);
  };

  const invokeNotification = async (notificationId: string) => {
    const notification = await getNotification(notificationId);
    
    if (notification && notification._id) {
      NotificationDialog.open({
        message: notification.metas[1],
        description: (
          <p
            dangerouslySetInnerHTML={{
              __html: notificationTypeToTextMap[notification.type].replace(/\{(\d+)\}/g, (g0, g1) => notification.metas[parseInt(g1, 10)])
            }}
          />
        ),
        style: {
          cursor: "pointer"
        },
        onClick: () => notification.metas[0] && notification.type == 2 ? push(`/books/${notification.metas[0]}/redirect-to-first-chapter`) : null,
        duration: 10000,
        icon: notification.img
          ? <Avatar src={notification.img} />
          : <Avatar src={notification.img} >{notification.metas[0][0].toLocaleUpperCase()}</Avatar>,
      });

      appendNotification(notification);
    }
  };

  useEffect(() => {
    if (socket) {
      if (user && user._id) {
        socket.send(
          JSON.stringify({
            action: "register-user",
            data: {
              userId: user._id
            }
          })
        );

        socket.onmessage = (evt) => {
          const data = JSON.parse(evt.data as string);
          // check if data exists
          if (data) {
            //check if type is notification
            if (data.type === "invoke-notification") {
              invokeNotification(data.data.id);
              loadUnreadCount();
            }
          }
        };
      }
    }
  }, [socket, user]);

  useEffect(() => {
    loadUnreadCount();
  }, []);

  useEffect(() => {
    if (notifPop) {
      loadNotifications();
    }
  }, [notifPop]);

  return isOnline ? (
    <Popover
      overlayClassName="notification-popover"
      title={<NotificationHeader />}
      placement="bottomRight"
      content={(
        <div
          id="notificationList"
          className="notification-list-inner"
        >
          <InfiniteScroll
            dataLength={notifications.length}
            next={loadNotifications}
            hasMore={hasMore}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            style={{
              height: "auto",
              maxHeight: 400
            }}
            height={400}
            endMessage={<Divider plain><span className="subtext">That's all the notifications</span></Divider>}
            scrollableTarget="notificationList"
            pullDownToRefresh={true}
            pullDownToRefreshThreshold={50}
            refreshFunction={loadNotifications}
            pullDownToRefreshContent={
              <p className="text-center">&#8595; Pull down to refresh</p>
            }
            releaseToRefreshContent={
              <p className="text-center">&#8593; Release to refresh</p>
            }
          >
            <NotificationList notifications={uniqBy(notifications, "_id")} />
          </InfiniteScroll>
        </div>
      )}
      trigger={"click"}
      open={notifPop}
      onOpenChange={handleNotifOpen}
    >
      <div className="nav-icon">
        <NotificationNavButton />
      </div>
    </Popover>
  ) : null;
});