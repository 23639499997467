import React, {  useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

// store
import useRootStore from "../../store/useRootStore";

// utils
import { getBookMetaSummaryForCache, mapChaptersToItems } from "../../utils/sidebar";
import { getAncestralChapters } from "../../utils/chapter";
import { SideBarChapterItem } from "../../types/sidebar";

//Loading animation
import AtticusAnimation_v2 from "../../content/images/AtticusAnimation_v2.gif";

import useBookSyncWebsocket from "../../utils/hooks/useBookSyncWebsocket";
import { syncRemoteBookWithLocalDB } from "../../utils/sync/helper";
import { debounce } from "lodash";
import {  GetBookFromDB, GetChapterMetaFromDB } from "../../utils/offline.book.helpers";
import { UpdateBookParams } from "../../types/book-sync";
import { useOnlineStatus } from "../../utils/hooks/isOffline";

const BookProvider: React.FC = ({ children }) => {
  const {
    book,
    setBook,
    getChapterById,
    getAndSetCurBook,
    getCurrentStoredBook,
    resetSearchAndReplace,
    next
  } = useRootStore().bookStore;
  const { chapterMeta, setCurrentChapter, setCurrentScene, setChapterMeta, getChapterMeta } = useRootStore().chapterStore;
  const { init, toggleChapterCollapse, sceneCache } = useRootStore().sideMenuStore;
  const { getAndSetTheme } = useRootStore().themeStore;
  const { loadUserProfile } = useRootStore().authStore;
  const { loadTemplates } = useRootStore().chapterStore;
  const { isBookLocalUpdate, setIsBookLocalUpdate, changeEvent, syncChapterId, syncChapterType, isChapterLocalUpdate, setIsChapterLocalUpdate, setChangeEvent, setSyncChapterId, isChapterMetaSave, setIsChapterMetaSave } = useRootStore().bookSyncWebSocketStore; 
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { getAndSetCollaboratorsForBook } = useRootStore().collaborationStore;
  const isOnline = useOnlineStatus();
  const [mounted, setMounted] = useState(false);
  const { bookId, chapterId } = useParams() as { bookId: string, chapterId: string };
  //to trigger useEffect for setCurChapter
  const [ isSyncMessageReceived, setIsSyncMessageReceived ] = useState<boolean>(false);

  const mountBook = async () => {
    try {
      if (!bookId) throw "Book ID not found";
      else {
        //Get Book from Db
        const b = await getAndSetCurBook(bookId, isOnline);

        if (!b) throw "Book not found";
        else {
          //get and set theme after book is set
          const t = await getAndSetTheme(b.themeId);

          // initSceneCache();
          //get book with chapter meta
          const book = getCurrentStoredBook();
          const chapterMeta = [...book.frontMatter, ...book.chapters];
          let initialChapterId: string | undefined;

          if (
            chapterId &&
            (b.chapterIds.includes(chapterId) ||
              b.frontMatterIds.includes(chapterId))
          ) {
            initialChapterId = chapterId;
          } else if (b.chapters && b.chapters.length > 0) {
            //if first chapter is volume, then go to its' next chapter
            if (b.chapters[0].type === "volume") {
              initialChapterId = book.chapters[1]._id;
            } else {
              initialChapterId = book.chapters[0]._id;
            }
          } else if (b.frontMatterIds && b.frontMatterIds.length > 0) {
            initialChapterId = book.frontMatter[0]._id;
          }
          //TODO:BODY Verify this
          const chaptersToMap = {frontMatter: book.frontMatter, chapters: book.chapters};
          // const initialSideBarChapterItem = mapChaptersToItems(chaptersToMap, sceneCacheMap).find(item => item.type === "chapter" && item.chapter._id === initialChapterId) as SideBarChapterItem;
          const initialSideBarChapterItem = mapChaptersToItems(chaptersToMap, sceneCache).find(item => item.type === "chapter" && item.chapter._id === initialChapterId) as SideBarChapterItem;
          
          if (initialChapterId && initialSideBarChapterItem) {
            // expand all chapters up to the initial chapter
            for (const ancestralChapter of getAncestralChapters(
              initialChapterId,
              chapterMeta
            )) {
              toggleChapterCollapse(ancestralChapter._id, "expand");
            }
            init(
              chapterMeta,
              setCurrentChapter,
              setCurrentScene,
              initialSideBarChapterItem,
              book._id
            );
            // await getAndSetCurChapter(initialSideBarChapterItem.chapter._id);
            await setCurrentChapter(initialSideBarChapterItem.chapter._id);
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const onBookIdChange = async () => {
    await loadUserProfile();
    if (bookId) {
      mountBook().then(() => setMounted(true));
      getAndSetCollaboratorsForBook(bookId);
    }
  };
  
  useEffect(() => {
    onBookIdChange();
  }, [bookId]);

  useEffect(() => {
    if (chapterId) {
      // if (sceneIndex !== null) {
      //   resetSelectedChapters();
      // }
      // getAndSetCurChapter(chapterId, sceneIndex);
      // getAndSetCurChapter(chapterId);
      setCurrentChapter(chapterId);
    }
  }, [chapterId, isSyncMessageReceived]);

  const socket = useBookSyncWebsocket(bookId);

  const handleRecivedUpdate = async (event) => {
    const message = JSON.parse(event.data);
    
    if (message.type === "book-update") {
      const { bookId, changeEvent, syncData } = message.data;
      if (bookId) {
        try{
          // Trigger debouncedFetchUpdatedBook after database update
          await debouncedFetchUpdatedBook(bookId, changeEvent, syncData);
        }finally{
          setIsBookLocalUpdate(false);
        }
      }
    }

    if (message.type === "chapter-update"){
      const { bookId, changeEvent, syncData } = message.data;
      if (bookId) {
          // Trigger debouncedFetchUpdatedChapter after database update
          await deboucnedFetchUpdatedChapter(bookId, changeEvent, syncData);
      }
    }

    if (message.type === "masterpage-update"){
      const { bookId, chapterIds } = message.data;
      if(bookId){
        for ( const chapterId of chapterIds){
          await deboucnedFetchUpdatedChapter(bookId, "masterpage-update",chapterId);
        }
      }
        
    }
  };

  //Invalidate PDF cache based on changeEvent
  const refreshPdfCacheBasedOnUpdate = async (bookId, changeEvent, syncData) => {

    const { chapterCacheData } = getBookMetaSummaryForCache(book.frontMatter, book.chapters);
  
    switch (changeEvent) {
      case "full-page-image-chapter-delete": {
        refreshCache(bookId, "full-page-image-chapter-delete", { "full-page-image-chapter-delete": { chapterId: syncData, chapters: chapterCacheData } });
        break;
      }
      case "chapter-delete": {
        refreshCache(bookId, "chapter-delete", { "chapter-delete": { chapterId: syncData, chapters: chapterCacheData } });
        break;
      }
      case "full-page-image-chapter-add":
      case "chapter-add":
      case "chapter-merge": {
        const { frontMatterIds, chapterIds } = getCurrentStoredBook();
        const allChapterIds = [...frontMatterIds, ...chapterIds];
        const chapterData = await getChapterById(allChapterIds);
        const chapterCacheDataUpdated = chapterData.map(({ _id, type, startOn }) => ({
          chapterId: _id,
          chapterType: type,
          startOn
        } as IPDFCacheStore.ChapterCacheMetaData));
          
        refreshCache(bookId, changeEvent, {
          [changeEvent]: { chapters: chapterCacheDataUpdated }
        });
        break;
      }
      case "chapter-title-change":
      case "chapter-subtitle-change":
      case "chapter-contents-change":
      case "full-page-image-chapter-change": {
        refreshCache(bookId, changeEvent, {
          [changeEvent] : { chapterId: syncData },
        });
        break;
      }
      case "toc-properties-change": {
        refreshCache(bookId, changeEvent);
        break;
      }
      case "chapter-properties-change": {
        refreshCache(bookId, "chapter-properties-change", {
          "chapter-properties-change": {
            chapter: {
              chapterId: syncData,
              chapterType: chapterMeta.type,
              startOn: chapterMeta.startOn || "any",
              includeIn: chapterMeta.includeIn || "all",
              ...chapterMeta.configuration
            },
          },
        });
        break;
      }
      default: {
        return;
      }
    }
  };

  const fetchUpdatedBook = async (bookId, changeEvent, syncData) => {
    try {
      const isCollaborated = book.collaborated ?? false;
      await syncRemoteBookWithLocalDB(bookId, isCollaborated);
      const result = await GetBookFromDB(bookId, true);

      // Update the book object after retrieving data
      setBook({ ...book, ...result });

      // Switch to the previous chapter if a chapter is deleted 
      if(changeEvent === "chapter-delete"){
        next(syncData,book.chapters);
      }
    } catch (error) {
      console.error("Error updating book:", error);
    } finally {
      refreshPdfCacheBasedOnUpdate(bookId, changeEvent, syncData);
    }
  };

  const fetchUpdatedChapter = async (bookId, changeEvent, syncData) => {
    try {
      const { _id: currentChapterId} = getChapterMeta();
      setIsSyncMessageReceived(!isSyncMessageReceived);
      const syncChapterId = syncData;
      const isCollaborated = book.collaborated ?? false;
      await syncRemoteBookWithLocalDB(bookId, isCollaborated);
      const result = await GetBookFromDB(bookId, true);

      // Update the book object after retrieving data
      setBook({ ...book, ...result });
      const chapterRes = await GetChapterMetaFromDB(syncChapterId);

      //load masterpages
      if( changeEvent === "save-as-masterpage" || changeEvent === "masterpage-update" ) {
        await loadTemplates();
      }

      if (syncChapterId === currentChapterId && chapterRes) {
        setChapterMeta(chapterRes);
      }
    } catch (error) {
      console.error("Error updating book:", error);
    } finally {
      refreshPdfCacheBasedOnUpdate(bookId, changeEvent, syncData);
    }
  };

  const debouncedFetchUpdatedBook = debounce(fetchUpdatedBook, 2000);
  const deboucnedFetchUpdatedChapter = debounce(fetchUpdatedChapter, 2000);

  useEffect(() => {
    return () => {
      // Clean up the debounce function when the component unmounts
      debouncedFetchUpdatedBook.cancel();

      //Reset Find and Replace params
      resetSearchAndReplace();
    };
  }, []);

  useEffect(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.onmessage = (e) => {
        handleRecivedUpdate(e);
      };
    }
  }, [socket, book]);

  const updateBook = (params : UpdateBookParams) => {
    const {bookId, changeEvent, data} = params;
    if (socket && socket.readyState === WebSocket.OPEN ) {
      socket.send(JSON.stringify({
        action: "book-update", 
        data: { 
          bookId: bookId, 
          changeEvent: changeEvent,
          syncData: data
        } 
      }));
    }
    setIsBookLocalUpdate(false);
    setChangeEvent(null);
    setSyncChapterId(null);
  };

  const updateChapter = (params : UpdateBookParams) => {
    const {bookId, changeEvent, data} = params;
    if (socket && socket.readyState === WebSocket.OPEN ) {
      socket.send(JSON.stringify({
        action: "chapter-update", 
        data: { 
          bookId: bookId, 
          changeEvent: changeEvent,
          syncData: data
        } 
      }));
    }
    setIsChapterLocalUpdate(false);
    setChangeEvent(null);
    setIsChapterMetaSave(false);
  };

  useEffect(() => {
    if(isBookLocalUpdate){
      const bookId = book._id;
      if(changeEvent === "full-page-image-chapter-delete" || changeEvent === "chapter-delete" || changeEvent === "chapter-title-change"){
        updateBook({bookId, changeEvent, data:syncChapterId});
      }else if(changeEvent === "full-page-image-chapter-add" || changeEvent === "chapter-add"){
        updateBook({bookId, changeEvent, data:syncChapterType});  
      }else if(changeEvent === "chapter-merge"){
        updateBook({bookId, changeEvent});
      }else{
        updateBook({bookId});
      }
    }
  }, [book, isBookLocalUpdate]); 

  useEffect(() => {
    if (isChapterLocalUpdate && isChapterMetaSave) {
      const bookId = book._id;
      if (
        changeEvent === "chapter-properties-change" ||
        changeEvent === "chapter-title-change" ||
        changeEvent === "chapter-subtitle-change" ||
        changeEvent === "chapter-contents-change" ||
        changeEvent === "toc-properties-change" ||
        changeEvent === "full-page-image-chapter-change" ||
        changeEvent === "chapter-merge" ||
        changeEvent === "save-as-masterpage"
      ) {
        updateChapter({ bookId, changeEvent, data: chapterId });
      }
    }
  }, [chapterMeta, isChapterLocalUpdate, isChapterMetaSave]);

  useEffect(() => {
    // close the socket when the component unmounts or bookId changes.
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [bookId, socket]);

  return mounted ? (
    <div>
      {children}
    </div>
  ) : (
    <div className="loading-animation-container">
      <img className="loading-animation" src={AtticusAnimation_v2} />
    </div>
  );
};

export const WithBook = observer(BookProvider);