import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row, Input, Modal } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";
import { MyRootBlock } from "../Plate/config/typescript";
import { replaceYChapterContent } from "../../utils/y";

const { TextArea } = Input;
type FormFields = {
  chapterBody: string;
};

const JSONPreviewer = (): JSX.Element => {
  const [form] = Form.useForm();
  const [show, setshow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [editable, setEditable] = useState(false);
  const { chapterMeta, chapterBody } = useRootStore().chapterStore;

  const updateYChapterBody = async (fields: FormFields) => {
    setSubmitting(true);
    const chapterBody = JSON.parse(fields.chapterBody) as MyRootBlock[];
    const currentChapterId = chapterMeta._id;
    try {
      await replaceYChapterContent(currentChapterId, chapterBody);
      setSubmitting(false);
      setEditable(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      setEditable(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      chapterBody: JSON.stringify(toJS(chapterBody), null, 4),
    });
  }, [chapterBody, form]);

  return (
    <>
      <Button className="btn" type="primary" onClick={() => setshow(!show)}>
        JSON
      </Button>
      <Modal
        title={
          <Row align="middle">
            <h3>JSON Previewer</h3>
            <Button type="link" onClick={() => setEditable(!editable)}>
              {editable ? "View" : "Edit"} JSON
            </Button>
          </Row>
        }
        closable={false}
        width={720}
        open={show}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          initialValues={{
            chapterBody: JSON.stringify(toJS(chapterBody), null, 4),
          }}
          onFinish={updateYChapterBody}
          form={form}
        >
          <Row>
            <Col xs={24}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingBottom: 10}}>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={submitting}
                  disabled={!editable}
                >
                  Update Chapter
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item<FormFields>
                name="chapterBody"
                style={{ backgroundColor: "red" }}
              >
                <TextArea disabled={!editable} autoSize></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="ghost"
                  style={{ marginRight: 10 }}
                  onClick={() => setshow(false)}
                >
                  Close
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={submitting}
                  disabled={!editable}
                >
                  Update Chapter
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default observer(JSONPreviewer);
