import React from "react";
import { observer } from "mobx-react";
import { Col } from "antd";

import useRootStore from "../../store/useRootStore";
import { FullScreen } from "../../components/Shared/Layouts/FullScreen";
import { EditorWrapper } from "../../components/Plate";
import { EditorRightMenu } from "../../components/Editor/RightMenu/EditorRightMenu";
import { ChapterTypesWithoutEditor } from "../../utils/chapter";
import { MetaContainer } from "./meta";

import "../../components/Editor/index.scss";

const isWithoutEditor = (v: IChapterStore.ChapterType): v is IChapterStore.ChapterTypeWithoutEditor =>
  ChapterTypesWithoutEditor.includes(v as IChapterStore.ChapterTypeWithoutEditor);


export const EditorContainer = observer(() => {
  const { book } = useRootStore().bookStore;
  const { chapterMeta } = useRootStore().chapterStore;
  const { editor_setting, sidebarPreviewerOnlyMode, chapterTemplateView } = useRootStore().appStore;

  return (
    <FullScreen className={sidebarPreviewerOnlyMode ? "writing-section" : ""}>
      {isWithoutEditor(chapterMeta.type) ? (
        <div className="extra-content">
          <MetaContainer
            chapter={chapterMeta}
            type={chapterMeta.type}
            currentBook={book}
          />
        </div>
      ) : (
        <EditorWrapper />
      )}

      {editor_setting.show && sidebarPreviewerOnlyMode && !chapterTemplateView ? (
        <Col
          id="sidebar-previewer-only"
          flex={editor_setting.view === "previewer" ? "" : "320px"}
          className="setting-area-wrapper"
        >
          <EditorRightMenu view={editor_setting.view} />
        </Col>
      ) : null}
    </FullScreen>
  );
});