import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Avatar, Col, Form, Menu, Row, Input } from "antd";
import { formatDistanceToNowStrict } from "date-fns";

import useRootStore from "../../../../../store/useRootStore";
import {
  MenuIcon,
  ResolveCommentIcon,
  SubmitCommentIcon,
} from "../../../../../content/icons";
import {
  BackgroundColor,
  Button,
  ButtonType,
} from "../../../../Shared/Buttons";
import { AtticusDropdown } from "../../../../Shared/Dropdowns";
import { TComment } from "../types";
import { handleCommentKeyDown } from "../utils/commentKeyHandlers";
import { BookCollaborator } from "../../../../../types/collaboration";

interface CommentItemProps {
  comment: TComment;
}

interface CommentThreadProps {
  resolveComment?: (commentId: string) => void;
  submitReplyComment?: (replyValue: string) => void;
  deleteComment?: (comment: TComment) => void;
  updateComment?: (comment: TComment) => void;
  thread: ICommentsStore.TCommentThread;
}

export const CommentThread = observer(
  ({
    resolveComment,
    submitReplyComment,
    deleteComment,
    updateComment,
    thread,
  }: CommentThreadProps) => {
    const { activeCommentId, setActiveCommentId, getCommentById } =
      useRootStore().commentsStore;
    const { user } = useRootStore().authStore;
    const { bookCollaborators } = useRootStore().collaborationStore;
    const { book } = useRootStore().bookStore;
    const { activeTrackChangeId, setActiveTrackChangeId } =
      useRootStore().trackChangesStore;

    const [editingComment, setEditingComment] = useState<TComment | null>(null);
    const [newValue, setNewValue] = useState<string>("");

    const activeCommentRef = useRef<HTMLDivElement | null>(null);
    const [form] = Form.useForm();

    const { TextArea } = Input;

    const getCommentedUser = (userId: string): BookCollaborator | undefined => {
      const filteredCollaborators = bookCollaborators.filter(collaborator => collaborator.userId === userId);
      const acceptedUser = filteredCollaborators.find(collaborator => collaborator.status === "accepted");
      const removedUser = filteredCollaborators.find(collaborator => collaborator.status === "removed" || collaborator.status === "removed_by_self");
      if(acceptedUser) return acceptedUser; 
      else if(removedUser) return removedUser;
      else return filteredCollaborators[0];
    };

    const getDuration = (timestamp): string => {
      return formatDistanceToNowStrict(timestamp)
        .replace(/ seconds?/, "s")
        .replace(/ minutes?/, "min")
        .replace(/ hours?/, "h")
        .replace(/ days?/, "d")
        .replace(/ months?/, "mo")
        .replace(/ years?/, "y");
    };

    const escapeHtml = (text: string): string => {
      return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    };

    const urlify = (text: string) => {
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      const escapedText = escapeHtml(text); 
      return escapedText.replace(urlPattern, (url) => {
        const escapedUrl = escapeHtml(url);
        return `<a href="${escapedUrl}" target="_blank" rel="noopener noreferrer">${escapedUrl}</a>`;
      });
    };

    const CommentItem: React.FC<CommentItemProps> = ({ comment }) => {
      const isParent = !comment.parentId;
      const [editingValue, setEditingValue] = useState<string>("");
      const commentedUser = getCommentedUser(comment.userId);
      const isCommentedUser = comment.userId === user?._id;
      const parentComment = getCommentById(comment.parentId);
      const isThreadResolved = comment.isResolved ?? parentComment?.isResolved ?? false;
      const bookOwner = bookCollaborators.find((collaborator) => collaborator.type === "Author");
      const isBookOwner = bookOwner?.userId === user?._id;
      const showMenu = (!isThreadResolved && isCommentedUser) || (isParent && isThreadResolved && isBookOwner) ? true : false;

      useEffect(() => {
        if (editingComment) {
          setEditingValue(editingComment.value);
        }
      }, [editingComment]);

      const saveUpdatedComment = () => {
        if (updateComment && editingComment && editingValue !== "") {
          const updatedComment: TComment = {
            ...editingComment,
            value: editingValue,
          };
          updateComment(updatedComment);
          setEditingComment(null);
        }
      };

      return (
        <Row className={`comment-item ${!isParent ? "reply" : ""}`}>
          <Col span={24}>
            <Row>
              <Col className="avatar-col" span={3}>
                <Avatar
                  size={32}
                  src={
                    commentedUser && commentedUser.profilePictureURL
                      ? commentedUser.profilePictureURL
                      : ""
                  }
                />
              </Col>
              <Col
                span={
                  !isParent
                    ? 16
                    : comment.isResolved || book.userId !== user?._id
                    ? 10
                    : 8
                }
                className="user-details-col"
              >
                <Row>
                  <Col className="user-name">
                    {commentedUser?.firstName} {commentedUser?.lastName}
                  </Col>
                </Row>
                <Row className="user-type-row">
                  <Col className="user-type">{commentedUser?.type} {commentedUser?.status && (commentedUser.status === "removed" || commentedUser.status === "removed_by_self") ? "(removed)": ""}</Col>
                </Row>
              </Col>
              {isParent ? (
                <Col span={6} className="comment-label-col">
                  <div className="comment-label"> Comment </div>
                </Col>
              ) : null}

              {isParent && !comment.isResolved && book.userId === user?._id ? (
                <Col className="resolve-comment-col" span={2}>
                  <div
                    className="resolve-icon-wrapper"
                    onClick={() => {
                      if (resolveComment) {
                        resolveComment(comment.id);
                      }
                    }}
                  >
                    <ResolveCommentIcon />
                  </div>
                </Col>
              ) : null}

              <Col className="duration" span={showMenu ? 4 : 5}>
                {getDuration(comment.createdAt)}
              </Col>
              {showMenu ? (
                <Col span={1}>
                  <AtticusDropdown
                    placement="bottomRight"
                    trigger={["click"]}
                    className="comment-options"
                    overlay={
                      <Menu>
                        {!isThreadResolved && isCommentedUser ? (
                          <Menu.Item
                            onClick={() => {
                              setEditingComment(comment);
                            }}
                          >
                            Edit
                          </Menu.Item>
                        ) : (
                          <></>
                        )}
                        {showMenu ? (
                          <Menu.Item
                            onClick={(info) => {
                              info.domEvent.stopPropagation();
                              if (deleteComment) {
                                deleteComment(comment);
                              }
                            }}
                          >
                            Delete
                          </Menu.Item>
                        ) : (
                          <></>
                        )}
                      </Menu>
                    }
                  >
                    <div>
                      <MenuIcon className="comments-menu" />
                    </div>
                  </AtticusDropdown>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Col>
          {editingComment && editingComment.id === comment.id ? (
            <>
              <TextArea
                autoSize
                value={editingValue}
                placeholder="Enter your comment"
                onChange={(event) => {
                  setEditingValue(event.target.value);
                }}
                onKeyDown={(e) => handleCommentKeyDown(e, saveUpdatedComment)}
              />
              <Row className="edit-comment-btns">
                <Col className="save-btn">
                  <Button
                    type={ButtonType.PRIMARY}
                    backgroundColor={BackgroundColor.GREEN}
                    disabled={editingValue === ""}
                    onClick={saveUpdatedComment}
                  >
                    Save
                  </Button>
                </Col>
                <Col>
                  <Button
                    type={ButtonType.SECONDARY}
                    backgroundColor={BackgroundColor.GREEN}
                    onClick={() => {
                      setEditingComment(null);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Col span={24}>
              <div
                className="comment-text"
                dangerouslySetInnerHTML={{ __html: urlify(comment.value) }}
              ></div>
            </Col>
          )}
        </Row>
      );
    };

    const submitReply = () => {
      if (submitReplyComment) {
        submitReplyComment(newValue);
        setNewValue("");
      }
    };

    useEffect(() => {
      if (activeCommentId && activeCommentRef.current) {
        activeCommentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [activeCommentId]);

    return (
      <>
        <div
          id={thread.comment.id}
          className={`comment-thread ${
            thread.comment.id === activeCommentId ? "active" : ""
          }`}
          ref={thread.comment.id === activeCommentId ? activeCommentRef : null}
          key={thread.comment.id}
          onClick={() => {
            if (activeTrackChangeId) {
              setActiveTrackChangeId(null);
            }
            setActiveCommentId(thread.comment.id);
            if (activeCommentId !== thread.comment.id) {
              setNewValue("");
            }
          }}
        >
          <div className="comment-thread-top"></div>
          <div className="thread-content-wrapper">
            <CommentItem comment={thread.comment} key={thread.comment.id} />
            {thread.replies.length > 0 &&
              thread.replies.map((reply) => (
                <CommentItem comment={reply} key={reply.id} />
              ))}
            {!thread.comment.isResolved ? (
              <Row>
                <Col className="avatar-col" span={3}>
                  <Avatar
                    size={32}
                    src={
                      user && user.profilePictureURL
                        ? user.profilePictureURL
                        : ""
                    }
                  />
                </Col>
                <Col span={21}>
                  <Form
                    className="reply-comment-form"
                    form={form}
                    layout="vertical"
                  >
                    <Form.Item>
                      <div className="text-area-container">
                        <TextArea
                          className="reply-text-area"
                          autoSize
                          placeholder="Reply"
                          value={
                            thread.comment.id === activeCommentId
                              ? newValue
                              : ""
                          }
                          onChange={(event) => {
                            setNewValue(event.target.value);
                          }}
                          onKeyDown={(e) =>
                            handleCommentKeyDown(e, submitReply)
                          }
                        />
                        <Button
                          className="submit-reply-btn"
                          type={ButtonType.GHOST}
                          htmlType="submit"
                          onClick={submitReply}
                          disabled={newValue === ""}
                        >
                          <SubmitCommentIcon />
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
);