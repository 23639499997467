import React, { useEffect, useState } from "react";
import { Table, Row, Col, Spin } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { BookIcon, MasterPageIcon } from "../../content/icons";
import useRootStore from "../../store/useRootStore";
import { BookCardDropdown } from "../../components/Books";
import { BookCollaboration } from "../../types/collaboration";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { BaseBook } from "../../types/book";

interface ParsedBook extends BaseBook {
  actionItem?: ItemType[];
  collabs?: BookCollaboration;
}

const collabSelectors = {
  "co_author": "Co-writer",
  "editor": "Editor",
  "beta_reader": "Beta reader"
};

export const CollaboratedBookListView = ({
  items,
}: {
  items: IShelfStore.ShelfItem[];
}): JSX.Element => {

  const { collaborated_books_collaborations, getCollaboratorFullName } = useRootStore().collaborationStore;

  const [parsedBooks, setParsedBooks] = useState<ParsedBook[]>([]);

  useEffect(() => {
    const collaborationsMap = new Map(
      collaborated_books_collaborations.map((collabs_item) => [collabs_item.bookId, collabs_item])
    );

    const updatedBooks: ParsedBook[] = items.map((item) => {
      const update = collaborationsMap.get(item.item._id);
      return { ...item.item, actionItem: item.actionItem, collabs: update } as ParsedBook;
    });

    setParsedBooks(updatedBooks);
  }, [collaborated_books_collaborations, items]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "40%",
      render: (
        _,
        item:
          | IBookStore.Book
          | IChapterStore.IChapterTemplateBase
      ) => {
          const dataItem = item as
            | IBookStore.Book
            | IChapterStore.IChapterTemplateBase;
          // check if item is of type book
          const isBookItem = !!(dataItem as IBookStore.Book).frontMatterIds;
          const isInitial = isBookItem && (dataItem as IBookStore.Book).isInitial;
          const coverImage =
            isBookItem && (dataItem as IBookStore.Book).coverImageUrl
              ? `url(${(dataItem as IBookStore.Book).coverImageUrl})`
              : "none";
          const link = isBookItem ?  `/books/${dataItem._id}/${(dataItem as IBookStore.Book).chapterIds[0]}` : `/chapter-template/${dataItem._id}`;
          const icon = isInitial ? (
            <Spin />
          ) : isBookItem ? (
            <BookIcon color="#3568BA" />
          ) : (
            <MasterPageIcon color="#3568BA" />
          );
          return (
            <Row gutter={16} className="book-list-item" align="middle">
              <Col>
                <Link to={isInitial ? "" :link}>
                  <Row
                    justify="center"
                    align="middle"
                    style={{
                      backgroundImage: coverImage,
                    }}
                    className="book-list-item-img"
                  >
                    {coverImage === "none" && icon}
                  </Row>
                </Link>
              </Col>
              <Col flex={1}>
                <h4>{dataItem.title}</h4>
              </Col>
            </Row>
          );
      },
    },
    {
      title: "Invited by",
      dataIndex: "collabs",
      key: "invitedBy",
      width: "15%",
      render: (collabs) => getCollaboratorFullName(collabs.ownerId)
    },
    {
      title: "Role",
      dataIndex: "collabs",
      key: "role",
      width: "10%",
      render: (collabs) => collabSelectors[collabs.type]
    },
    {
      title: "Joined Date",
      dataIndex: "collabs", 
      key: "joinedDate",
      width: "10%",
      render: (collabs) => (collabs.lastUpdateAt ? moment(collabs.lastUpdateAt).format("MMM D, YYYY") : "-"),
    },
    {
      title: "Last modified",
      dataIndex: "lastUpdateAt",
      key: "lastModified",
      width: "10%",
      render: (dt) => (dt ? moment(dt).fromNow() : "-"),
    },
    {
      title: "",
      dataIndex: "actionItem",
      key: "action",
      width: "5%",
      render: (actionItem) =>
        actionItem && actionItem.length != 0 ? <BookCardDropdown actions={actionItem} /> : "-",
    },
  ];
  
  return (
    <Table
      bordered
      columns={columns}
      dataSource={parsedBooks}
      pagination={false}
      expandable={{ expandIcon: () => null }}
      rowClassName={item => (item as IBookStore.Book)?.isInitial ? "loading-book-list-item" : "" }
    />
  );
};
