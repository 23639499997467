import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Avatar, Col, Form, Menu, Row, Input, Space } from "antd";
import { Value, getNodeString } from "@udecode/plate";

import useRootStore from "../../../../../store/useRootStore";
import { DeclineIcon, MenuIcon, SubmitCommentIcon, TickSquareIcon } from "../../../../../content/icons";
import {
  BackgroundColor,
  Button,
  ButtonType,
} from "../../../../Shared/Buttons";
import { AtticusDropdown } from "../../../../Shared/Dropdowns";

import { TTrackChange, TrackChangeReply } from "../../track-changes/types";
import { parseChapter } from "../../../../../press/exportEpub/parsers";
import { MyInlineChildren } from "../../../config/typescript";
import { BookCollaborator } from "../../../../../types/collaboration";
import { can } from "../../../../casl/helpers";
import { handleTrackChangeKeyDown } from "../utils/trackChangeKeyHandler";
import { formatDistanceToNowStrict } from "date-fns";

interface TrackChangeThreadProps {
  approveTrackChange?: (trackChange: TTrackChange[]) => void;
  declineTrackChange?: (trackChange: TTrackChange[]) => void;
  submitReplyTrackChange?: (
    replyValue: Value,
    trackChange: TTrackChange
  ) => void;
  updateTrackChangeReply?: (
    trackChangeNode: TTrackChange,
    updatedReply: string,
    reply: TrackChangeReply
  ) => void;
  deleteReply?: (trackChange: TTrackChange, reply: TrackChangeReply) => void;
  thread: TTrackChange[];
}

export const TrackChangeThread = observer(
  ({
    approveTrackChange,
    declineTrackChange,
    submitReplyTrackChange,
    updateTrackChangeReply,
    deleteReply,
    thread,
  }: TrackChangeThreadProps) => {
    const { user } = useRootStore().authStore;
    const { activeTrackChangeId, setActiveTrackChangeId } =
      useRootStore().trackChangesStore;
    const { activeTheme: theme } = useRootStore().themeStore;
    const { activeCommentId, setActiveCommentId } = useRootStore().commentsStore;
    const { bookCollaborators } = useRootStore().collaborationStore;
    const { chapterMeta } = useRootStore().chapterStore;

    const [newValue, setNewValue] = useState<Value>([
      { type: "p", children: [{ text: "" }] },
    ]);
    const [editingReply, setEditingReply] = useState<TrackChangeReply | null>(
      null
    );

    const getDuration = (timestamp): string => {
      return formatDistanceToNowStrict(timestamp)
        .replace(/ seconds?/, "s")
        .replace(/ minutes?/, "min")
        .replace(/ hours?/, "h")
        .replace(/ days?/, "d")
        .replace(/ months?/, "mo")
        .replace(/ years?/, "y");
    };

    const activeTrackChangeRef = useRef<HTMLDivElement | null>(null);
    const [form] = Form.useForm();

    const { TextArea } = Input;

    const submitReply = (trackChange: TTrackChange) => {
      if (submitReplyTrackChange) {
        submitReplyTrackChange(newValue, trackChange);
        setNewValue([{ type: "p", children: [{ text: "" }] }]);
      }
    };

    const getTrackChangeUser = (userId: string): BookCollaborator | undefined => {
      const collaborator = bookCollaborators.find(
        (collab) => collab.userId === userId
      );
      return collaborator;
    };


    //Track Change Parent Container

    const TrackChangeItem = ({
      trackChange,
    }: {
      trackChange: TTrackChange[];
    }) => {

      const [operationText, setOperationText] = useState("");
      const trackChangeUser = getTrackChangeUser(trackChange[0].tc.trackChanges.userId);
      const initialTrackChange = trackChange[0];

      useEffect(() => {
        const operation = trackChange[0].tc.trackChanges.operation;

        switch (operation) {
          case "insert":
            setOperationText("Added");
            break;
          case "delete":
            setOperationText("Deleted");
            break;
          case "update":
            setOperationText("Replaced");
            break;
          case "format":
            setOperationText("Format");
            break;
          default:
            setOperationText("");
        }
      }, []);

      const leafNodes: MyInlineChildren = [];
      const deleteNodes: MyInlineChildren = [];
      let isUpdate = false;

      trackChange.forEach((thread) => {
        const { trackChanges, ...leafText } = thread.tc;
        if (
          trackChanges.operation === "update" ||
          trackChanges.operation === "insert"
        ) {
          if (trackChanges.operation === "update") {
            isUpdate = true;
          }
          leafNodes.push(leafText);
        } else {
          deleteNodes.push(leafText);
        }
      });

      const deleteNode = [
        {
          type: "p",
          children: deleteNodes,
        },
      ];

      const trackChangeNode = [
        {
          type: "p",
          children: leafNodes,
        },
      ];

      return (
        <Row className="track-change-item">
          <Col span={24}>
            <Row>
              <Col className="avatar-col" span={3}>
                <Avatar size={32} src={
                  trackChangeUser && trackChangeUser.profilePictureURL
                    ? trackChangeUser.profilePictureURL
                    : ""
                } />
              </Col>
              <Col span={14} className="user-details-col">
                <Row>
                  <Col className="user-name">
                    {trackChangeUser?.firstName} {trackChangeUser?.lastName}
                  </Col>
                </Row>
                <Row>
                  <Col className="user-type">{trackChangeUser?.type}</Col>
                </Row>
              </Col>
              <Col span={4} >
                <div className="track-change-label">Edit</div>
              </Col>
              <Col
                className="duration"
                span={2}
              >
                {getDuration(initialTrackChange.tc.trackChanges.createdAt)}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div className="track-change-text">
              <span>
                <b>{operationText}</b>:{" "}
              </span>
              {isUpdate ? (
                <>
                  '
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parseChapter(
                        { ...chapterMeta, children: deleteNode },
                        theme?.properties,
                        true
                      ),
                    }}
                  ></span>
                  '<b> with </b>'
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parseChapter(
                        { ...chapterMeta, children: trackChangeNode },
                        theme?.properties,
                        true
                      ),
                    }}
                  ></span>
                  '
                </>
              ) : (
                <span
                  style={{ paddingRight: "10px" }}
                  dangerouslySetInnerHTML={{
                    __html: parseChapter(
                      {
                        ...chapterMeta,
                        children:
                          leafNodes.length === 0 ? deleteNode : trackChangeNode,
                      },
                      theme?.properties,
                      true
                    ),
                  }}
                ></span>
              )}
            </div>
          </Col>
          <Col className="track-change-action-btns">
            <Row >
              <Space>
                {can("view", "approve-track-changes") &&
                  <Button
                    type="at-secondary"
                    size="small"
                    color="green"
                    backgroundColor="white"
                    icon={<TickSquareIcon />}
                    className="approve-btn"
                    onClick={() => approveTrackChange?.(trackChange)}
                  >
                    <b>Accept</b>
                  </Button>
                }

                <Button
                  type="at-secondary"
                  size="small"
                  backgroundColor="white"
                  className="decline-btn"
                  icon={<DeclineIcon />}
                  onClick={() => declineTrackChange?.(trackChange)}
                >
                  <b>Decline</b>
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      );
    };

    interface TrackChangeReplyItemProps {
      reply: TrackChangeReply;
      trackChange: TTrackChange;
    }

    //Track Change Reply Container
    const TrackChangeReplyItem = ({
      reply,
      trackChange,
    }: TrackChangeReplyItemProps) => {

      const [editingValue, setEditingValue] = useState<string>("");
      const replyUser = getTrackChangeUser(reply.userId);
      const showMenu = reply.userId === user?._id;

      const saveUpdatedReply = (
        trackChangeNode: TTrackChange,
        reply: TrackChangeReply
      ) => {
        if (updateTrackChangeReply) {
          updateTrackChangeReply(trackChangeNode, editingValue, reply);
          setEditingReply(null);
        }
      };

      return (
        <Row className={"track-change-item reply"}>
          <Col span={24}>
            <Row>
              <Col className="avatar-col" span={3}>
                <Avatar
                  size={32}
                  src={
                    replyUser && replyUser.profilePictureURL
                      ? replyUser.profilePictureURL
                      : ""
                  }
                />
              </Col>
              <Col span={showMenu ? 14 : 16} className="user-details-col">
                <Row>
                  <Col className="user-name">
                    {replyUser?.firstName} {replyUser?.lastName}
                  </Col>
                </Row>
                <Row>
                  <Col className="user-type">{replyUser?.type}</Col>
                </Row>
              </Col>
              <Col
                className="duration"
                span={showMenu ? 2 : 4}
              >
                {getDuration(reply.createdAt)}
              </Col>

              <Col className="duration" span={2}></Col>
              <Col span={1}>
                {reply.userId === user?._id &&
                  <AtticusDropdown
                    placement="bottomRight"
                    trigger={["click"]}
                    className="track-change-options"
                    overlay={
                      <Menu>
                        <Menu.Item
                          onClick={() => {
                            setEditingReply(reply);
                          }}
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            if (deleteReply) {
                              deleteReply(trackChange, reply);
                              setEditingReply(null);
                            }
                          }}
                        >
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <div>
                      <MenuIcon className="track-change-menu" />
                    </div>
                  </AtticusDropdown>
                }
              </Col>
            </Row>
          </Col>

          {editingReply && editingReply.replyId === reply.replyId ? (
            <>
              <TextArea
                autoSize
                defaultValue={reply.value}
                placeholder="Reply"
                onChange={(event) => {
                  setEditingValue(event.target.value);
                }}
              onKeyDown={(e)=>handleTrackChangeKeyDown(e, saveUpdatedReply, trackChange, reply)}
              />
              <Row className="edit-track-change-btns">
                <Col className="save-btn">
                  <Button
                    type={ButtonType.PRIMARY}
                    backgroundColor={BackgroundColor.GREEN}
                    onClick={() => saveUpdatedReply(trackChange, reply)}
                  >
                    Save
                  </Button>
                </Col>
                <Col>
                  <Button
                    type={ButtonType.SECONDARY}
                    backgroundColor={BackgroundColor.GREEN}
                    onClick={() => {
                      setEditingReply(null);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Col span={24}>
              <div
                className="track-change-text"
              >
                <span>{reply.value}</span>
              </div>
            </Col>
          )}
        </Row>
      );
    };

    useEffect(() => {
      if (activeTrackChangeId && activeTrackChangeRef.current) {
        activeTrackChangeRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [activeTrackChangeId]);

    const parentThread = thread[0];
    const replyArray = Object.values(parentThread.tc.replies ?? []);

    return (
      <div
        className={`track-change-thread ${parentThread.tc.trackChanges.tcId === activeTrackChangeId
            ? "active"
            : ""
          }`}
        ref={
          parentThread.tc.trackChanges.tcId === activeTrackChangeId
            ? activeTrackChangeRef
            : null
        }
        onClick={() => {
          if (activeCommentId) {
            setActiveCommentId(null);
          }
          setActiveTrackChangeId(parentThread.tc.trackChanges.tcId);
          if (activeTrackChangeId !== parentThread.tc.trackChanges.tcId) {
            setNewValue([{ type: "p", children: [{ text: "" }] }]);
          }
        }}
      >
        <div className="track-change-thread-top"></div>
        <div className="thread-content-wrapper-tc">
          <TrackChangeItem trackChange={thread} />
          {replyArray.length > 0 &&
            replyArray.map((reply, j) => (
              <TrackChangeReplyItem
                reply={reply}
                trackChange={parentThread}
                key={j}
              />
            ))}
          <Row>
            <Col className="avatar-col" span={3}>
              <Avatar
                size={32}
                src={
                  user && user.profilePictureURL ? user.profilePictureURL : ""
                }
              />
            </Col>
            <Col span={21}>
              <Form
                className="reply-track-change-form"
                form={form}
                layout="vertical"
              >
                <Form.Item>
                  <div className="text-area-container">
                    <TextArea
                      className="reply-text-area"
                      autoSize
                      placeholder="Reply"
                      value={
                        thread[0].tc.trackChanges.tcId === activeTrackChangeId
                          ? getNodeString(newValue[0])
                          : ""
                      }
                      onChange={(event) => {
                        setNewValue([
                          {
                            type: "p",
                            children: [{ text: event.target.value }],
                          },
                        ]);
                      }}
                      onKeyDown={(e)=>handleTrackChangeKeyDown(e, submitReply, parentThread)}
                    />
                    <Button
                      className="submit-reply-btn"
                      type={ButtonType.GHOST}
                      htmlType="submit"
                      onClick={() => submitReply(parentThread)}
                    >
                      <SubmitCommentIcon />
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
);
