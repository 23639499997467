import React, { useEffect, useState } from "react";
import { message } from "antd";
import { AtticusVerticalTab } from "../../components/Shared/Tabs";
import { ChangePassword, Profile } from "../../pages";
import { useHistory } from "react-router-dom";
import { FullScreen, ScrollContainer } from "../../components/Shared/Layouts";
import { AtticusClient } from "../../api/atticus.api";
import { ProfileFields } from "../../types/auth";
import { Button } from "../../components/Shared/Buttons";
import { useOnlineStatus } from "../../utils/hooks/isOffline";
import useRootStore from "../../store/useRootStore";
import { VersionOptions } from "../../pages/AboutCollaboration";

export const ProfileTab = (children) => {
  const history = useHistory();
  const isOnline = useOnlineStatus();
  const { authStore } = useRootStore();

  const handleTabChange = (key) => {
    history.push(`/account/${key}`);
  };

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    profilePictureURL: "",
    website: "",
    email: "",
  });

  useEffect(() => {
    const myProfile = async () => {
      const profileResponse = await AtticusClient.GetProfile();
      setProfile(profileResponse);
    };
    myProfile();
  }, []);

  const OnSubmitHandler = async (values: ProfileFields) => {
    try {
      const myprofile = {
        firstName: values.firstName,
        lastName: values.lastName,
        website: values.website,
        profilePictureURL: profile.profilePictureURL,
      };
      await AtticusClient.PatchProfile(myprofile);
      const profileResponse = await AtticusClient.GetProfile();
      setProfile(profileResponse);
      authStore.setUserProfile(profileResponse);
      message.success("Your profile has been updated successfully!", 3);
    } catch (e: any) {
      console.log({ e });
    }
  };

  return (
    <FullScreen>
      <div className='profile-page'>
        <div className='profile-setting-container'>
          <h2 className='profile-title'>Profile settings</h2>
          <div className='profile-container'>
            <ScrollContainer>
              <AtticusVerticalTab
                variant='primary-blue'
                accessKey='profile'
                onChange={handleTabChange}
                tabBarExtraContent={
                  <div className='logout-btn-div'>
                    <Button
                      className='logout-btn'
                      type='at-primary'
                      disabled={isOnline ? false : true}
                      onClick={() => history.push("/auth/sign-out")}
                    >
                      Log out
                    </Button>
                  </div>
                }
                items={[
                  {
                    key: "profile",
                    label: "My account",
                    children: (
                      <Profile
                        profile={profile}
                        OnSubmitHandler={OnSubmitHandler}
                      />
                    ),
                  },
                  {
                    key: "change-password",
                    label: "Password",
                    children: <ChangePassword />,
                  },
                  {
                    key: "versions",
                    label: "Version Options",
                    children: <VersionOptions />,
                  },
                ]}
              />
            </ScrollContainer>
          </div>
        </div>
      </div>
    </FullScreen>
  );
};
