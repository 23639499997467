import React, { } from "react";
import querystring from "querystring";
import { observer } from "mobx-react";
import { Divider, message, List, Row } from "antd";
import { groupBy, orderBy, sortBy } from "lodash";
import { useLocation } from "react-router-dom";

// partials
import BookListItem from "./BookListItem";
import ChapterTemplateItem from "./ChapterTemplateItem";
import NoBook from "../Shared/Empty/Book";
import { FullScreen } from "../Shared/Layouts/FullScreen";
import { IChapterTemplateBase } from "../../types/chapter";
import { GetAllChapterTemplates } from "../../utils/offline.book.helpers";

// stores
import useRootStore from "../../store/useRootStore";

type ParsedBook = (IBookStore.Book & { author: string, tag?: string });

const getBooksByTags = (books: ParsedBook[]): ParsedBook[] => {
  const bookList: ParsedBook[] = [];

  books.forEach((book) => {
    if (Array.isArray(book.versionTags) && book.versionTags.length > 0) {
      book.versionTags.map((versionTag) => {
        bookList.push({
          ...book,
          tag: versionTag,
        });
      });
    } else {
      bookList.push({
        ...book,
        tag: "",
      });
    }
  });

  return bookList;
};

const BookList = observer(() => {
	const { books, mounting, deleteBook } = useRootStore().shelfStore;
	const { deleteChapterTemplate } = useRootStore().chapterStore;
	const [chapterTemplates, setChapterTemplates] = React.useState<IChapterTemplateBase[]>();

	const { search } = useLocation();
	const queryObj = querystring.parse(search.replace("?", ""));

	const tab = queryObj.tab ? queryObj.tab : "recent";
	const searchTerm = queryObj.searchTerm ? queryObj.searchTerm : "";
	const searchString = searchTerm as string;
	const cleanSearchString = searchString.replace(/[&\\#,+()$~%.'":*?<>{}]/g, "");

	const sort = queryObj.sort ? queryObj.sort : "modified";

	const tmpBooks: ParsedBook[] = books.map((book) => ({ ...book, author: book.author.join(", ") })) as ParsedBook[];
	const rbooks = tmpBooks.sort((a, b) => new Date(a.modifiedAt || "").getTime() - new Date(b.modifiedAt || "").getTime()).reverse();
	const parsedBooks = sort === "alpha" ? orderBy(rbooks, ["title"], ["asc"]) : rbooks;

	const groupedByProject = groupBy(parsedBooks, "project");
	const booksByProject = sortBy(Object.keys(groupedByProject).map((key) => ({ group: key, books: groupedByProject[key] })), ["group"], ["asc"]);

	const groupedByAuthor = groupBy(parsedBooks, "author");
	const booksByAuthor = sortBy(Object.keys(groupedByAuthor).map((key) => ({ group: key, books: groupedByAuthor[key] })), ["group"], ["asc"]);

  const groupedByTags = groupBy(getBooksByTags(parsedBooks), "tag");
  const booksByTags = sortBy(
    Object.keys(groupedByTags).map((key) => ({
      group: key,
      books: groupedByTags[key],
    })),
    ["group"],
    ["asc"]
  );

	const onBookDelete = async (bookId: string) => {
		try {
			await deleteBook(bookId);
		} catch (e: any) {
			message.error(e.message, 4);
		}
	};


	// Chapter template library
	const renderTemplates = async () => {
		const getTempsFromDb = await GetAllChapterTemplates();
		// const asd = await AtticusClient.GetChapterTemplates();
		if (getTempsFromDb) {
			setChapterTemplates(getTempsFromDb);
		}
	};

	React.useEffect(() => {
		renderTemplates();
	}, [mounting, tab]);

	// Chapter template library
	const onTemplateDelete = async (templateId: string) => {
		try {
			await deleteChapterTemplate(templateId);
			renderTemplates();
		} catch (e: any) {
			message.error(e.message, 4);
		}
	};

	let list = <></>;

	if (tab === "recent") {
		list = (
			<div>
				<div className="book-list">
					{parsedBooks.map((book, i) => (
						<BookListItem
							book={{ ...book, author: book.author.split(", ") }}
							key={i}
							deleteBook={onBookDelete}
						/>
					))}
				</div>
			</div>
		);
	}

	if (tab === "project") {
		list = (
			<div>
				{booksByProject.map((p, i) => (
					<div key={i}>
						<h2 className="book-list-title">{p.group}</h2>
						<div className="book-list">
							{p.books.map((book, b) => (
								<div key={b}>
									<BookListItem
										book={{ ...book, author: book.author.split(", ") }}
										deleteBook={onBookDelete}
									/>
									<Divider />
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		);
	}

	if (tab === "author") {
		list = (
			<div>
				{booksByAuthor.map((p, i) => (
					<div key={i}>
						<h2 className="book-list-title">{p.group}</h2>
						<div className="book-list">
							{p.books.map((book, b) => (
								<div key={b}>
									<BookListItem
										book={{ ...book, author: book.author.split(", ") }}
										deleteBook={onBookDelete}
									/>
									<Divider />
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		);
	}

  if (tab === "tags") {
    list = (
      <div>
        {booksByTags.map((tagGroup, i) => (
          <div key={i}>
            <h2 className="book-list-title">{tagGroup.group}</h2>
            <div className="book-list">
              {tagGroup.books.map((book, b) => (
                <div key={b}>
                  <BookListItem
                    book={{ ...book, author: book.author.split(", ") }}
                    deleteBook={onBookDelete}
                  />
                  <Divider />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

	if (tab === "templates") {
		list = (
			<div className="book-list">
				{/* <div className="chapter-template-align chapter-template-align-break" key={`template-${i}`} onClick={() => { addNewChapterFromTemplate(elem, elem.type, elem.section); }}> {elem.title}</div> */}
				{chapterTemplates && chapterTemplates?.length > 0 ? (
					chapterTemplates && chapterTemplates.map((elem, i) => (
						<div key={i}>
							<ChapterTemplateItem
								// book={{ ...book }}
								chapterTemplate={elem}
								deleteTemplate={onTemplateDelete}
							/>
						</div>
					))
				) : (
					<div className="content chapter-template-empty">
						<Row justify="center" className="custom-themes-empty inner">
							<div>
								<img width="200" src="/images/dog.gif" />
							</div>
							<h2>No master pages created yet</h2>
							<br />
						</Row>
					</div>
				)

				}

			</div>
		);
	}

	if (searchTerm) {
		list = (
			<div>
				<h2 className="book-list-title">Search results for <b>{searchTerm}</b></h2>
				<div className="book-list">
					{books.filter(b => b.title.match(new RegExp(cleanSearchString as string, "ig"))).map((book, i) => (
						<BookListItem
							book={book} key={i}
							deleteBook={onBookDelete}
						/>
					))}
				</div>
			</div>
		);
	}

	return (
		<FullScreen scale>
			{books && books.length > 0 ? list : (
				<NoBook />
			)}
		</FullScreen>
	);
});

export default BookList;
