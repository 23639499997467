import { createPluginFactory } from "@udecode/plate";
import { withTrailingBlock } from "./normalizers/withTrailingBlock";

const ELEMENT_TRAILING_BLOCK = "trailingBlock";
/**
 * Enables support for paragraphs.
 */
export const createTrailingBlockPlugin = createPluginFactory({
  key: ELEMENT_TRAILING_BLOCK,
  isElement: true,
  withOverrides: withTrailingBlock
});