import React, { useState } from "react";
import { Menu, Modal, message } from "antd";
import { observer } from "mobx-react";
import useRootStore from "../../../store/useRootStore";

import { Dialog } from "../../Shared/Modal";
import { DeleteIcon } from "../../../content/icons";

interface DeleteThemeProps {
  themeId: string
}

const fallbackThemeId = process.env.REACT_APP_DEFAULT_THEME || "finch";

export const DeleteTheme = observer(({ themeId }: DeleteThemeProps) => {
  const { deleteTheme, changeThemeInBook } = useRootStore().themeStore;
  const { books, saveBook } = useRootStore().shelfStore;
  const [loading, setLoading] = useState(false);

  const onDeleteTheme = async () => {
    try {
      const promises: Promise<void>[] = [];
      const book_themes = books.filter(book => book.themeId === themeId);
      
      for (let i = 0; i < book_themes.length; i++) {
        const book = book_themes[i];

        // invoke api call to change theme
        promises.push(
          changeThemeInBook(
            book._id,
            fallbackThemeId
          )
        );

        // update theme switch to local books
        promises.push(
          saveBook(
            book._id,
            {
              themeId: fallbackThemeId,
            }
          )
        );
      }
      
      setLoading(true);

      // call them all at once
      await Promise.all(promises);

      // finally delete the theme
      await deleteTheme(themeId);

      setLoading(false);

    } catch (e: any) {
      message.error("Theme could not be deleted", 4);
    }
  };

  return (
    <Menu.Item
      key="delete-theme"
      onClick={() => {
        Dialog({
          title: "Delete Theme ?",
          content: books.filter(book => book.themeId === themeId).length > 1 ?
            (
              books.filter(book => book.themeId === themeId).length > 2 ?
                `Warning: ${books.filter(book => book.themeId === themeId).length - 1} other books are using the same theme`
                : `Warning: ${books.filter(book => book.themeId === themeId).length - 1} other book is using the same theme`
            )
            : "Are you sure you want to delete this theme?",
          centered: true,
          rightBtn: {
            type: "at-primary",
            danger: true,
            onClick: loading ? () => message.info("Theme is already being deleted") : onDeleteTheme,
            children: "Yes"
          },
          leftBtn: {
            type: "at-secondary",
            children: "No"
          }
        });
      }}
      icon={
        <DeleteIcon size={14} />
      }
    >
      <div className="theme-kebab-delete">Delete</div>
    </Menu.Item>
  );
});
