import { observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import useRootStore from "../../../store/useRootStore";
import { useChapterGeneratorAPI } from "../../../workers";
import { CallbackFn, CallbackFnType, ChapterGeneratorWorkerStatusResponseData, PDFReadyChapterData, RequestChapterCallbackFn } from "../../../workers/types";
import { getChapterNumber } from "../../../utils/chapter-numbering";
import { getImages } from "../../Previewer/print/helpers";
import { isValidChapter } from "../../../utils/toc";

const RendererCacheInitiator: React.FC = () => {
    const params = useParams();
    const { book, getChapterById, getChaptersForExports } = useRootStore().bookStore;

    const { activeTheme: theme, individualChapterImage } = useRootStore().themeStore;
    const { 
        initializeBookCache, 
        changeChaptersCacheStatus,
         getNextChapterTobeRendered, 
         bookExist, makeChapterAsValid, 
         setResetRendererCallback, 
         removeBookCache, 
         getEndNoteOffsetValue,
         refreshCache,
         debouncedRefreshCache
    } = useRootStore().pdfCacheStore;
    const { registerCallbacks, resetWorker } = useChapterGeneratorAPI();
    const { bookId } = params as { bookId: string };

    const getBookChaptersForCache = () => {
        const chapters = [...book.frontMatter, ...book.chapters];
        const validChapters = chapters.filter(chapter => isValidChapter(chapter._id, book));
        const validFrontMatterIds = book.frontMatterIds.filter(chapterId => isValidChapter(chapterId, book));
        const chaptersWithType = validChapters.map(({ _id, type, startOn, includeIn, parentChapterId, volume }) => 
            ({ chapterId: _id, chapterType: type, startOn, includeIn, parentChapterId, volume } as IPDFCacheStore.ChapterCacheMetaData));
        return { chaptersWithType, frontmatterIds: validFrontMatterIds };
    };

    const refreshPDFCacheOnChapterContentChange = (chapterId: string) => {
        refreshCache(bookId, "chapter-contents-change", { "chapter-contents-change": { chapterId: chapterId, invalidateOnly: "current" } });
        debouncedRefreshCache(bookId, "chapter-contents-change", { "chapter-contents-change": { chapterId: chapterId, invalidateOnly: "others"  } });
    };

    const handleChapterRequest: RequestChapterCallbackFn = useCallback(async () => {
        const validFrontMatterIds = book.frontMatterIds.filter(chapterId => isValidChapter(chapterId, book));
        const validChapterIds = book.chapterIds.filter(chapterId => isValidChapter(chapterId, book));
        //console.log("render cache");
        /**
         * TODO: 
         * 
         * Do we need the entire book, all the chapters with the chapter bodies to render another chapter ?
         * If we do, and there's no other way around it, keep the ydocs and the idb connection in a new store for easier sync of 
         * all the chapters ?
         */
        const chapterCacheData = getNextChapterTobeRendered(bookId);
        if(!chapterCacheData) return null;
        /**
         * Do the sync part of the getChapterById here and do the cache invalidation from this stateful component ?
         */
        const chaptersForExport = await getChaptersForExports([...validFrontMatterIds, ...validChapterIds]);
        const chapters: IChapterStore.Chapter[] = [];
        let chapterToRender: IChapterStore.Chapter | undefined;
        chaptersForExport.map(chapterData => {
            if(chapterData.shouldRefreshCache) {
                console.log("refreshing cache for chapter", chapterData.chapter._id);
                refreshPDFCacheOnChapterContentChange(chapterData.chapter._id);
            }
            if(chapterData.chapter._id === chapterCacheData?.chapterId) chapterToRender = chapterData.chapter;
            chapters.push(chapterData.chapter);
        });
        if (!chapterToRender || !theme) return null;
        //TODO:Verify
        const data: PDFReadyChapterData = {
            chapter: chapterToRender,
            book: { ...book, chapters: chapters },
            chapters: chapters,
            lastPageNumber: chapterCacheData.pageNumberOffset,
            chapterNumber: getChapterNumber(chapterToRender, {...book, chapters: chapters}),
            theme: theme,
            endNoteOffset: theme.properties.notesMode === "END_OF_BOOK" ? getEndNoteOffsetValue(book._id, chapterToRender._id) : 0,
            // styles: style || null,
            // images: getImages(chapter, style, images),
            // customThemeHeaders: customThemeHeaders || undefined,
            // hyphens: theme.paragraph.hyphens,
            tocSummary: chapterCacheData.tocSummary,
            previousDigest: chapterCacheData.digest
        };
        return JSON.stringify(data);
    }, [theme, book, getChapterById, getNextChapterTobeRendered, getChapterNumber, getImages]);

    const createWorkerResponseHandler = useCallback((bookId: string): CallbackFn<ChapterGeneratorWorkerStatusResponseData> => (type: CallbackFnType, taskId: string | null, message?: string, data?: ChapterGeneratorWorkerStatusResponseData) => {
        switch (type) {
            case "completed":
                if (!data) return;
                // eslint-disable-next-line no-case-declarations
                const { chapterId, digest, pageCount, blob } = data;
                // eslint-disable-next-line no-case-declarations
                const url = URL.createObjectURL(blob);
                makeChapterAsValid(bookId, chapterId, pageCount, url, data.endNoteCount, digest);
                break;
            case "digest-match":
                if(!message) return;
                changeChaptersCacheStatus(bookId, [message], "valid");
                break;
            default:
                break;
        }
    }, [makeChapterAsValid]);

    const reinitializeWorker = useCallback(() => {
        resetWorker();
        registerCallbacks(handleChapterRequest, createWorkerResponseHandler(book._id));
    }, [book, resetWorker, registerCallbacks, handleChapterRequest, createWorkerResponseHandler]);

    useEffect(() => {
        if (book?._id) {
            if (!bookExist(book._id)) {
                // get book chapter data
                const { chaptersWithType, frontmatterIds } = getBookChaptersForCache();
                // initialize cache store
                initializeBookCache(bookId, chaptersWithType, frontmatterIds);
            }
            setResetRendererCallback(reinitializeWorker);
            reinitializeWorker();
            /**
             * Iterate though all the yjs chapter bodies for the book
             * identify the ones that have recent edits
             * invalidate the cache 
             */
        }
    }, [book, bookExist, initializeBookCache, reinitializeWorker]);

    return <div></div>;
};

export default observer(RendererCacheInitiator);
