import React, { FunctionComponent } from "react";
import { Divider } from "antd";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import BodyDropdown from "../../BodyDropdown";
import { SideBarPlaceholderItem } from "../../../../types/sidebar";
import Can from "../../../casl/Can";

interface BodySeparatorProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  item: SideBarPlaceholderItem;
}

const BodySeperator: FunctionComponent<BodySeparatorProps> = ({
  snapshot,
  provided,
  item,
}) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...provided.draggableProps.style,
      }}
      className="sidebar-body-seperator"
    >
      {item.level === 0 ? (
        <>
          <p className="sidebar-body-seperator-title">Body</p>
          <div className="sidebar-body-seperator-main-divider-cover">
            <Divider className="sidebar-body-seperator-divider" orientation="left" />
          </div>
          <Can action={"view"} subject={"chapter-more-options"}>
            <BodyDropdown />
          </Can>
        </>
      ) : (
        <div className="sidebar-body-seperator-sub-divider-cover" style={{ marginLeft: `${item.level}rem` }}>
          <Divider className="sidebar-body-seperator-sub-divider" orientation="left" />
        </div>
      )}
    </div>
  );
};

export default BodySeperator;
