import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";
import {
  OnlineChecker,
  Synchronization,
  WritingHabitWordCounter,
} from "../Goals/WritingHabit/components";
import { Editor } from "./editor";

export const EditorWrapper = observer(() => {
  const { book, changeCount, chapterTemplate } = useRootStore().bookStore;
  const { chapterMeta: currentChapter, chapterBody: currentChapterBody } =
    useRootStore().chapterStore;
  const { setSidebarPreviewerOnlyMode, chapterTemplateView } =
    useRootStore().appStore;
    
  const [key, setKey] = useState<string>(Date.now().toString());
  const hasNewKeyBeenGenerated = useRef<boolean>(false);

  const generateNewKey = () => {
    setKey(Date.now().toString());
  };

  /** Trigger a state change on change of book, chapter or changeCount to re-render the Editor. */
  useEffect(() => {
    hasNewKeyBeenGenerated.current = true;
    generateNewKey();
  }, [
    book._id,
    currentChapter._id,
    changeCount,
    chapterTemplateView,
    chapterTemplate._id,
  ]);

  useEffect(() => {
    /** set setSidebarPreviewerOnlyMode to false for !isWithoutEditor() chapters unless it's a master page */
    setSidebarPreviewerOnlyMode(chapterTemplateView);
  }, [chapterTemplateView, setSidebarPreviewerOnlyMode]);

  const initialChapterBody = useMemo(() => {
    if (chapterTemplateView && currentChapterBody.length > 0) {
      return currentChapterBody;
    }
    return undefined;
  }, [chapterTemplateView, currentChapterBody]);

  return (
    <>
      <OnlineChecker />
      <Synchronization />
      <WritingHabitWordCounter />
      <Editor key={key} initialChapterBody={initialChapterBody} />
    </>
  );
});
