
import React from "react";
import {
  GreenPlayIcon,
  GreenPlayIconWithBox
} from "../../content/icons";

import { TextAndIconLink } from "../Shared/Links/TextWithIconLink";

export const TutorialCard = () => {
  return (
    <div className="book-action-tutorial-card">
      <div className="tutorial-card-left">
        <p className="left-txt-line-1">Learn how to use and write your ebook with Atticus</p>
        <p className="left-txt-line-2">Create professional print books and eBooks easily with Atticus.</p>
        <TextAndIconLink
          link={"https://www.atticus.io/tutorials"}
          text={"See tutorials"}
          icon={<GreenPlayIcon />}
          isExternalLink={true}
        />
      </div>
      <a className="tutorial-card-right" href="https://www.atticus.io/tutorials" target="_blank" rel="noreferrer">
        <GreenPlayIconWithBox />
      </a>
    </div>
  );
};