import React, { useEffect, useState } from "react";
import { Button, ButtonType } from "../components/Shared/Buttons";
import { AtticusClient } from "../api/atticus.api";
import useRootStore from "../store/useRootStore";
import { Dialog, Modal } from "../components/Shared/Modal";
import { observer } from "mobx-react";

export const VersionOptions = observer((): JSX.Element => {
  const { user, setUserProfile } = useRootStore().authStore;

  const [switchingToClassic, setSwitchingToClassic] = useState<boolean>(false);
  const [isClassicUserLocal, setIsClassicUserLocal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const switchToClassic = async () => {
    setSwitchingToClassic(true);
    await AtticusClient.UpdateToLimited();
    localStorage.setItem("isClassicUser", JSON.stringify(true));
    setIsClassicUserLocal(true);
    setSwitchingToClassic(false);
    setOpenModal(true);
  };

  useEffect(()=>{
    const isClassicUser = localStorage.getItem("isClassicUser");
    if (isClassicUser !== null) {
      setIsClassicUserLocal(JSON.parse(isClassicUser));
    }
  },[]);

  const openDialog = async () => {
    return Dialog({
      open: false,
      title: "Move to the Limited Version",
      content:
        <p>By switching to the Limited Version, you agree to work without multi-device syncing or collaboration tools. Any changes made in the Limited Version will <b>not sync</b> to the Current Version, and using both versions for the same project may cause content conflicts.
        <br/><br/>
        You will receive an email with the link within 5-10 minutes.
        <br/><br/>
        <b>Do you want to move to the Limited Version?</b>
        </p>,
      rightBtn: {
        type: "at-primary",
        danger: true,
        onClick: () => {
          switchToClassic();
        },
        children: "Yes",
      },
      leftBtn: {
        type: "at-secondary",
        children: "No",
      },
    });
  };

  const ConvertingNoticeDialog =()=> {
    return (<Modal
          open={openModal}
          centered={true}
          onCancel={()=>setOpenModal(false)}
          destroyOnClose={true}
          rightBtn={{
            type: "at-primary",
            danger: true,
            onClick: ()=>setOpenModal(false),
            children: "Okay",
          }}
        >
          <p>
          Great! We are working on syncing your content. 
          Please log out from this version of Atticus and wait for your email with the link to the Limited Version.
          </p>
        </Modal>);
  };

  return (
    <>
      <ConvertingNoticeDialog/>
      <h2>Version Options for Atticus</h2>
      <br/>
      <p>
        Atticus now allows you to choose between the <b>Current Version</b> and the <b>Limited Version</b>. 
        These options allow you to choose the version that best meets your needs while maintaining an optimal experience.
        The <b>Current Version</b> is the default and recommended choice for most authors, offering the latest features, including collaboration and multi-device syncing capabilities.
      The <b>Limited Version</b> is an alternative for authors who are experiencing performance issues with the current version and who do not need the capability of collaboration at this time.</p>
      <br/>
      <h2>Which Version Should You Choose? </h2>
      <br/>
      <h3>Current Version (Default):</h3>
      <p>
        This version supports multi-device syncing and real-time collaboration, making it the best option for authors who:
      </p>
      <ul>
        <li>Work across multiple devices.</li>
        <li>Collaborate with co-writers, editors, or beta readers.</li>
        <li>Regularly experience interrupted internet service.</li>
        <li>Want access to the latest tools and updates.</li>
      </ul>
      <p>The Current Version is the most up-to-date and optimized for authors who rely on robust functionality.</p>
      <br/>
      <h3>Limited Version (Recommended for Performance Issues):</h3>
      <p>This version is recommended <b>only for authors experiencing performance issues</b> with the Current Version. 
      It does not support multi-device syncing or real-time collaboration but allows you to continue working on your 
      manuscript without interruptions caused by system limitations.</p>
      <br/>
      <h2>Important Considerations Before Switching</h2>
      <br/>
      <h3>
        Content Will Not Sync Between Versions:
      </h3>
      <br/>
      <ul>
        <li>Changes made in the Limited Version will <b>not sync</b> to the Current Version, and vice versa.</li>
        <li>Working in both versions can cause <b>content conflicts</b> or <b>corruption</b>.</li>
        <li>To avoid this, use only one version of Atticus.</li>
      </ul>
      <br/>
      <h2>What Happens When You Switch to the Limited Version?</h2>
      <br/>
      <p>Your data will sync from the Current Version to the Limited Version and you will receive an email within 
        5–10 minutes containing a link to access the Limited Version.</p>
      <br/>
      <h3>Uninstalling the Atticus App:</h3>
      <br/>
      <ul>
        <li>
        If you’ve installed the Atticus app, you will need to uninstall it before switching to the Limited Version.</li>
        <li>To uninstall:</li>
        <ul>
          <li>Open the Atticus app.</li>
          <li>Click on the three-dot menu in the top-right corner of your browser.</li>
          <li>Select <b>Uninstall Atticus</b> or <b>Remove from Chrome</b>.</li>
        </ul>
        <li>Once you receive the email with the link to access the Limited Version, you can bookmark the link and/or reinstall the Atticus app.</li>
      </ul>
    <br/>
      <h2>Returning to the Current Version</h2>
      <br/>
      <ul>
        <li>Changes made in the Limited Version will <b>not automatically</b> sync back to the Current Version.</li>
        <li>To return to the Current Version, contact our support team at <b>support@atticus.io</b> to restore functionality and ensure your content is up to date.</li>
      </ul>
      <br/>
      <Button
        type={ButtonType.PRIMARY}
        onClick={async () => openDialog()}
        disabled={user?.isAtticusClassicUser || !user || isClassicUserLocal}
        loading={switchingToClassic}
      >
        Move to the Limited Version
      </Button>
    </>
  );
});
