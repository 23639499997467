import { generateToc, isValidChapter, sanitizeTocTitles } from "../utils/toc";
import {
  PdfSlateEndnote,
  PdfEndnoteSubheading,
  PdfChapterEndnotes,
} from "../components/Previewer/print/types";
import { ImageNode } from "../components/Plate/config/typescript";
import { getBookSubheads, getSubheadsOfChapter } from "../utils/get-book-subheads";
import { Theme } from "../types/theme";
import { getChapterAncestorVolume } from "../utils/chapter";
import { getChapterNumber } from "../utils/chapter-numbering";

import {
  getChapterHeaderImageToRender,
  getCompressedImageUrl,
  hasCompressedImageSFX,
} from "./exportEpub/helpers";
import {
  parseChapter,
  parseFullPageImage,
  parseTitleCard,
  parseEndNoteChapter,
  parseVolumeToc
} from "./exportEpub/parsers";
import { compiledEpub, epubContentBlock } from "./types";

export const Compiler = async (
  book: IBookStore.ExportBook,
  themeProps: Theme,
  bookEndnotes: (PdfSlateEndnote | PdfEndnoteSubheading)[] | PdfChapterEndnotes[],
): Promise<compiledEpub> => {
  const blocks: epubContentBlock[] = [];

  const { properties: themeStyleProps } = themeProps;
  const endnotes: (ISlateStore.SlateEndnote | PdfEndnoteSubheading)[] = [];
  let endnoteBlockIndex = -1;

  /** Replace book cover image url with it's compressed image url */
  if (book.coverImageUrl?.includes(hasCompressedImageSFX)) {
    book.coverImageUrl = getCompressedImageUrl(book.coverImageUrl);
  }

  /** Replace theme image url with it's compressed image url */
  if (themeProps.properties.image.url?.includes(hasCompressedImageSFX)) {
    themeProps.properties.image.url = getCompressedImageUrl(themeProps.properties.image.url);
  }

  // find where the table of contents is
  let tocIndex = -1;
  let toc = false;
  let tocTitle = "Contents";
  const chapters = book.frontMatter?.concat( book.chapters as IChapterStore.Chapter[]);
  const bookSubheadings = getBookSubheads(chapters as IChapterStore.Chapter[]);
  // finding the endnotes chapter
  let endnoteChapter : IChapterStore.Chapter | undefined = book?.chapters?.find((chapter) => chapter.type === "endnotes");
  let bookTocChapter : IChapterStore.Chapter | undefined = undefined; 

  if (book.frontMatterIds && book.frontMatter) {
    tocIndex = book.frontMatter.findIndex(
      (chapter) =>
        chapter.type === "toc" &&
        (chapter.includeIn === undefined ||
          chapter.includeIn === "all" ||
          chapter.includeIn === "ebook")
    );
    if (tocIndex !== -1) {
      bookTocChapter = book.frontMatter[tocIndex];
      tocTitle = book.frontMatter[tocIndex].title;
      toc = true;
    }
  }

  if(chapters){
    for (let c = 0; c < chapters.length; c += 1) {
      const chapter = chapters[c];
      // image compression for exports
      if (chapter.type === "chapter") {
        // chapter body image compression
        for (let cv = 0; cv < chapter.children.length; cv++) {
          if (chapter.children[cv].type === "image") {
            const imgChild: ImageNode = chapter.children[cv];
            if (imgChild.url.includes(hasCompressedImageSFX)) {
              imgChild.url = getCompressedImageUrl(imgChild.url);
            }
          }
        }

        // individual chapter image compression
        if (chapter?.image?.includes(hasCompressedImageSFX)) {
          chapter.image = getCompressedImageUrl(
            chapter.image as string
          );
        }
      }

      // full page image compression
      if (chapter.fullpageImage) {
        const imgChild: IChapterStore.FullPageImage = chapter
          .fullpageImage as IChapterStore.FullPageImage;
        if (imgChild?.imageUrl) {
          if (imgChild.imageUrl.includes(hasCompressedImageSFX)) {
            imgChild.imageUrl = getCompressedImageUrl(imgChild.imageUrl);
          }
        }
      }

      if (chapter) {
        if (!isValidChapter(chapter._id, book)) continue;
        let numbered = false;
        const curChapterNumber = getChapterNumber(
          chapter,
          book as IBookStore.ExpandedBook
        );

        if (
          (chapter.type === undefined ||
            chapter.type === "chapter" ||
            chapter.type === "uncategorized" ||
            chapter.type === "custom") &&
          (chapter.numbered === undefined || chapter.numbered)
        ) {
          numbered = true;
        }

        let parsed = "";
        const parsedEndnotes: ISlateStore.SlateEndnote[] = [];
        if (chapter.type === "image") {
          parsed = parseFullPageImage(chapter);
        } else if (chapter.type === "endnotes") {
          if (themeStyleProps.ePubNotesMode !== "END_OF_BOOK") continue;
        } else if (chapter.type === "toc") {
          if (!getChapterAncestorVolume(chapter._id, chapters || [])) continue;
          parsed = parseVolumeToc(book as IBookStore.ExpandedBook, chapter);
        } else if (chapter.type === "title") {
          parsed = parseTitleCard(book as IBookStore.ExpandedBook, chapter);
        } else {
          const headerImage = await getChapterHeaderImageToRender(
            themeStyleProps,
            chapter
          );
          parsed = parseChapter(
            chapter,
            themeStyleProps,
            undefined,
            numbered && book.chapterIds.includes(chapter._id) ? curChapterNumber : undefined,
            undefined,
            true,
            headerImage,
            parsedEndnotes,
            !themeStyleProps.ePubEndnotesChapterSettings.categorizeByTitle? endnotes.length : undefined,
            endnoteChapter,
            bookSubheadings
          );
        }
        if (
          chapter.includeIn === undefined ||
          chapter.includeIn === "all" ||
          chapter.includeIn === "ebook"
        ) {
          endnotes.push(...parsedEndnotes);
          const subheads = getSubheadsOfChapter(
            chapter.children as ISlateStore.SlateBaseParentBlockNode[],
            chapter._id
          ).map((subheading) => subheading.children[0]);

          if (chapter.type === "endnotes") {
            endnoteBlockIndex = blocks.length;
          }

          const blockContent = book.frontMatterIds.includes(chapter._id) ? {beforeToc: !!(tocIndex !== -1 && c < tocIndex)} : {
            chapterNumber: curChapterNumber, 
            subtitle: book.showTocSubtitle ? chapter.subtitle : null,
            subheads:
              book.showTocSubheading && subheads.length > 0
                ? subheads
                : undefined,
            numbered,
          };

          blocks.push({
            blockId: chapter._id,
            title: chapter.title ? chapter.title : chapter._id,
            content: `<div class=${themeProps._id}>${parsed}</div>`,
            isTitle: !!chapter.title,
            chapterType: chapter.type,
            filename: chapter._id,
            ...blockContent
          });
        }
      }
    }
  }

  if (endnoteBlockIndex !== -1 && endnoteChapter) {
    if (bookEndnotes.length === 0) {
      blocks.splice(endnoteBlockIndex, 1);
      if (book.chapters) {
        // hide the endnote chapter from the TOC if no endnotes in the book
        const endnoteChapterIndex = book.chapters?.findIndex(
          (chapter) => chapter._id === endnoteChapter?._id
        );
        endnoteChapter = {
          ...endnoteChapter,
          configuration: { showInTableOfContents: false },
        };
        book.chapters[endnoteChapterIndex] = endnoteChapter;
      }
    } else {
      let parsedEndnotes = "";
      if (themeProps.properties.ePubNotesMode === "END_OF_BOOK") {
        parsedEndnotes = parseEndNoteChapter(
          endnoteChapter,
          bookEndnotes,
          themeProps.properties,
          true
        );
      }
      blocks[
        endnoteBlockIndex
      ].content = `<div class=${themeProps._id}>${parsedEndnotes} </div>`;
    }
  }

  const tocContent = generateToc(
    book as IBookStore.ExpandedBook,
    chapters as IChapterStore.Chapter[],
    "ebook",
    bookTocChapter
  );

  return {
    epubContent: blocks,
    showTOC: toc,
    tocContent: sanitizeTocTitles(tocContent),
    tocTitle,
  };
};
