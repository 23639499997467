import {
  PlateEditor,
  Value,
  findNode,
  isElement,
  liftNodes,
} from "@udecode/plate-core";
import { ELEMENT_SCENE } from "../../ornamental-break";
import { Location } from "slate";
import { NodeType } from "../../types";

export const withPageBreak = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E
) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    const isRoot = path.length === 1;
    let isInsideAScene = false;
    if(!isRoot) {
      const at = editor.selection ? editor.selection : path;
      const targetNode = findNode(editor, {
        at,
        match: { type: ELEMENT_SCENE },
      });
      isInsideAScene = (targetNode && ((targetNode[0] as NodeType).type) === ELEMENT_SCENE) ?? false;
    }

    if (isElement(node) && node.type === "page-break" && !isRoot && !isInsideAScene) {
      liftNodes(editor, {
        at: path
      });

      return;
    }

    normalizeNode(entry);
  };

  return editor;
};
