import React from "react";
import { find, findIndex, defaultsDeep } from "lodash";
import { Modal } from "antd";
import { makeAutoObservable, observable, toJS } from "mobx";
import { AtticusClient } from "../api/atticus.api";

// sync functions
import {
  SavePreviewDevice,
  GetPreviewDeviceDB,
  GetAllPreviewDevices,
  GetPreviewDeviceCount,
  GetAllThemesFromIDB,
  SaveNewThemeInIDB,
  OverwriteThemeInIDB,
  UpdateBookInDB,
  DeleteThemeFromIndexedDB,
} from "../utils/offline.book.helpers";

import { deviceSpec } from "../utils/device-configs";
import {
  DEFAULT_THEME_ID,
  themedefaults,
  themeFormDefaults,
} from "../utils/initials";
import {
  themeResponseToTheme,
  themeToFormFields,
  sanitizeThemeMetaData,
  parseResponseToTheme,
  sortThemes,
} from "../components/Theme/helpers";
import { Dialog } from "../components/Shared/Modal";

const fallbackThemeId = process.env.REACT_APP_DEFAULT_THEME || "finch";


export class ThemeStore {
  activeTheme: IThemeStore.Theme = themedefaults;
  /**
   * stores a duplicate of activeTheme to restore the active theme when edit theme
   * form is discarded
   */
  duplicateTheme: IThemeStore.Theme = themedefaults;
  allThemes : IThemeStore.ThemesWithCollaboration[] = [];
  isThemeBuilderActive = false;
  themeFormFields: IThemeStore.ThemeFields = themeFormDefaults;
  images: any = {
    chapterId: "",
    url: "",
    background: false,
    colored: "all",
    placement: "below-subtitle",
    alignment: "left",
    width: 100,
    printExtent: "margins",
  };
  customCss = "";
  individualChapterImage = false;
  saving = false;
  synced = false;

  isCurrentThemeDirty = false;

  /* deprecated - pagination */
  activeThemePage = 1;

  constructor() {
    makeAutoObservable(this, {
      activeTheme: observable,
    });
  }

  get allUserThemes () : IThemeStore.Theme[] {
    return this.allThemes.filter(theme => !theme.collaborated);
  }

  setActiveTheme = (theme: IThemeStore.Theme): void => {
    this.activeTheme = theme;
  };

  setAllThemes = (themes: IThemeStore.ThemesWithCollaboration[]): void => {
    this.allThemes = themes;
  };


  /**
   * Adds the default theme to the list of all themes if the default theme doesn't
   * already exists in the list of all themes.
   * This is necessary since the 'Create a new theme' button is rendered as the 'thumbnail'
   * for the default theme.
   */
  appendDefaultThemeIfNeeded = (
    themes: IThemeStore.Theme[]
  ): IThemeStore.Theme[] => {
    const defaultThemeExistsInThemes = themes.some(
      (theme) => theme._id === DEFAULT_THEME_ID
    );
    if (defaultThemeExistsInThemes) return themes;
    return [...themes, themedefaults];
  };

  switchTheme = async (
    bookId: string,
    theme: IThemeStore.Theme
  ): Promise<void> => {
    try {
      this.setActiveTheme(theme);
      await this.changeThemeInBook(bookId, theme._id);
    } catch (e) {
      console.log(e);
    }
  };

  changeThemeInBook = async (bookId: string, themeId: string) => {
    try {
      /** Update book's themeId in MongoDB */
      await AtticusClient.UpdateThemeInBook(bookId, themeId);
      /** Update book's themeId in IDB */
      await UpdateBookInDB(bookId, { themeId: themeId });
    } catch (e) {
      console.log(e);
    }
  };

  setThemeToEdit = (theme: IThemeStore.Theme): void => {
    this.duplicateTheme = this.activeTheme;
    this.activeTheme = theme;
    this.setThemeFormFields(themeToFormFields(theme));
    this.setIsCurrentThemeDirty(false);
    this.setIsThemeBuilderActive(true);
  };

  discardThemeBuilder = (): void => {
    this.setIsCurrentThemeDirty(false);
    this.setIsThemeBuilderActive(false);
  };

  saveNewCustomTheme = async (
    params: IThemeStore.ThemePayload,
    bookId?: string
  ): Promise<string | undefined> => {
    try {
      const sanitizedTheme = sanitizeThemeMetaData(params);
      /** save new theme in MongoDB*/
      const response = await AtticusClient.SaveNewTheme(sanitizedTheme);
      /** save new theme in IDB */
      await SaveNewThemeInIDB(response);
      const theme = themeResponseToTheme(response);
      /** Add new theme to app state */
      this.setAllThemes([...this.allThemes, theme]);
      /** Set new theme as book theme */
      if (bookId) {
        await this.switchTheme(bookId, theme);
      }
      this.discardThemeBuilder();
      return theme._id;
    } catch (e: any) {
      console.log("Failed to save new theme", e.message);
    }
  };

  editTheme = async (
    params: IThemeStore.ThemePayload,
    isOnline: boolean
  ): Promise<void> => {
    try {
      let updatedTheme = params;

      if (isOnline) {
        /** update theme in MongoDB*/
        const response = await AtticusClient.SaveTheme(params);
        updatedTheme = themeResponseToTheme(response);
      } else {
        // enable sync
        updatedTheme = {
          ...updatedTheme,
          allChangesSynced: false,
        } as IThemeStore.ThemeResponse;
      }

      // serialise the theme object
      const finalTheme: IThemeStore.Theme = {
        ...updatedTheme,
        fonts: toJS(updatedTheme.fonts),
        properties: toJS(updatedTheme.properties),
      };

      /** update app state */
      this.setAllThemes(
        this.allThemes.map((theme) =>
          theme._id === finalTheme._id ? finalTheme : theme
        )
      );
      this.discardThemeBuilder();
      /** update theme in IDB */
      await OverwriteThemeInIDB(finalTheme._id, finalTheme);
    } catch (e: any) {
      console.log("Failed to edit theme", e.message);
    }
  };

  discardEditThemeForm = (): void => {
    this.activeTheme = this.duplicateTheme;
    this.discardThemeBuilder();
  };

  setIndividualChapterImage = async (is: boolean) => {
    this.individualChapterImage = is;
  };

  setThemeFormFields = (fields: IThemeStore.ThemeFields): void => {
    this.themeFormFields = fields;
  };

  setCustomCss = (c: string): void => {
    this.customCss = c;
  };

  setImages = (imgs: any): void => {
    this.images = imgs;
  };

  setSaving = (saving: boolean): void => {
    this.saving = saving;
  };

  setSynced = () => {
    this.synced = true;
  };

  setIsThemeBuilderActive = (state: boolean): void => {
    this.isThemeBuilderActive = state;
  };

  setCustomThemeHeaders = (headers: IThemeStore.ThemeStyleProps): void => {
    this.activeTheme = {
      ...this.activeTheme,
      properties: headers,
    } as IThemeStore.Theme;
  };

  toggleFavourite = async (styleId: string, isFavourite: boolean) => {
    const after = () => {
      if (this.activeTheme._id === styleId) {
        this.setActiveTheme({
          ...this.activeTheme,
          isFavourite,
        });
      }

      const themes = this.allThemes.map((theme) => {
        if (theme._id !== styleId) return theme;

        const updatedTheme = {
          ...theme,
          isFavourite,
        };

        return updatedTheme;
      });

      const afterHook = () => {
        OverwriteThemeInIDB(styleId, {
          allChangesSynced: true,
        });
      };

      if (isFavourite) {
        AtticusClient.AddThemeToFavourites(styleId).then(afterHook);
      } else {
        AtticusClient.RemoveThemeFromFavourites(styleId).then(afterHook);
      }
      this.setAllThemes(themes);
    };

    await OverwriteThemeInIDB(styleId, {
      isFavourite,
      allChangesSynced: false,
    }).then(after);
  };

  deleteTheme = async (themeId: string): Promise<void> => {
    try {
      //save to server
      await AtticusClient.DeleteTheme(themeId);

      // save to store
      this.setAllThemes(this.allThemes.filter((t) => t._id !== themeId));

      // Delete from IndexedDB
      await DeleteThemeFromIndexedDB(themeId);

      //If the deleting theme is also the active theme then make the active theme to aether
      if (themeId === this.activeTheme._id) {
        const fallbackTheme = find(this.allThemes, { _id: fallbackThemeId });
        if (fallbackTheme) {
          this.setActiveTheme(fallbackTheme);
        }
      }
    } catch (e: any) {
      console.log("delete user theme error", e);
    }
  };

  setClosestTheme = (id: string) => {
    if (this.activeTheme._id && id) {
      if (this.allThemes.length > 1) {
        const ind = findIndex(this.allThemes, { _id: id });
        this.setActiveTheme(this.allThemes[ind == 0 ? ind + 1 : ind - 1]);
      } else {
        this.setActiveTheme(this.allThemes[0]);
      }
    }
  };

  loadThemes = async (): Promise<void> => {
    // Get Updated Themes from IDB
    const updatedThemes = await GetAllThemesFromIDB();

    const parsedThemes = parseResponseToTheme(updatedThemes);

    // append default theme
    const themesWithDefault = this.appendDefaultThemeIfNeeded(parsedThemes);

    //sort themes
    const sortedThemes = sortThemes(themesWithDefault);

    //deperecated - set page number with the new re-ordered list
    //this.setPageNumber(sortedThemes);

    //save all themes to local store
    this.setAllThemes(sortedThemes);
    this.setSynced();
  };

  mountThemes = async () => {
    // Get latest theme updates from IDB
    const themes = parseResponseToTheme(await GetAllThemesFromIDB());

    const themesWithDefault = this.appendDefaultThemeIfNeeded(themes);

    const sortedThemes = sortThemes(themesWithDefault);

    // deperecated - set page number with the new re-ordered list
    //this.setPageNumber(sortedThemes);

    // re-assign to state with sorting
    this.setAllThemes(sortedThemes);
  };


  getAndSetTheme = async (themeId: string) => {
    try {
      const themeFromStore = find(this.allThemes, { _id: themeId });
      if (themeFromStore) {
        this.setActiveTheme(themeFromStore); //set theme
      } else {
        return themedefaults;
      }
    } catch (e: any) {
      console.log(e.message);
      throw e;
    }
  };

  confirmExitEdit = (
    cancel: () => void = () => undefined,
    ok: () => void = () => undefined,
    fragments: IThemeStore.ExitModalProps = {
      title: "Leave the custom theme builder?",
      description:
        "It seems like you are trying to leave the theme builder without saving your changes.",
      ok: "Continue working",
      cancel: "Leave without saving",
    }
  ): void => {
    const onCancel = () => {
      this.discardEditThemeForm();
      cancel();
    };

    if (!this.isCurrentThemeDirty) {
      onCancel();
      return;
    }

    Dialog({
      title: fragments.title,
      content: fragments.description,
      rightBtn: {
        className: "btn-a",
        backgroundColor: "green",
        style: {
          flex: 1,
        },
        onClick: ok,
        children: fragments.ok
      },
      leftBtn: {
        style: {
          flex: 1,
        },
        children: fragments.cancel,
        onClick: onCancel
      },
      onCancel: ok
    });
  };

  getDevicePreview = async (
    deviceName: string
  ): Promise<IThemeStore.DeviceSpec | undefined> => {
    try {
      // this.setLoading(true);
      const devPreview = await GetPreviewDeviceDB(deviceName);
      return devPreview;
    } catch (e: any) {
      console.log("Previewer error", e.message);
      throw e;
    }
  };

  getAllDevices = async () => {
    const all = await GetAllPreviewDevices();
    if (all) {
      all.forEach((face) => {
        const img = new Image();
        img.src = face;
      });
    }
    return all;
  };

  saveDevicePreview = async (): Promise<void> => {
    const devices = deviceSpec.devices;
    await SavePreviewDevice(devices);
  };


  mountPreviewDevices = async (): Promise<void> => {
    const deviceCount = await GetPreviewDeviceCount();

    if(!(deviceCount && deviceCount > 1)){
      const devices = deviceSpec.devices;
      await SavePreviewDevice(devices); 
    }
  }

  /**
   * Updates whether the "currently edited theme" is "dirty"
   * "Dirty" meaning, it has any unsaved changes.
   *
   * @param {boolean} isDirty
   */
  setIsCurrentThemeDirty = (isDirty: boolean): void => {
    this.isCurrentThemeDirty = isDirty;
  };

  /* deprecated - pagination */

  setActivePage = (index: number) => {
    this.activeThemePage = index;
  };
  
  setPageNumber = (themes: IThemeStore.Theme[]) => {
    themes.some((theme, i) => {
      if (theme._id === this.activeTheme?._id) {
        const themeNumber = i + 1;
        const pageNumber = Math.ceil(themeNumber / 8);
        this.setActivePage(pageNumber);
      }
    });
  };

}

export default new ThemeStore();
