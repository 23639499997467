import _ from "lodash";
import { makeAutoObservable } from "mobx";

import { TTrackChange } from "../components/Plate/plugins/track-changes/types";

export class TrackChangesStore {
  trackChanges: TTrackChange[][] = [];
  activeTrackChangeId: string | null = null;
  isTrackChanges = false

  constructor() {
    makeAutoObservable(this);
  }

  setTrackChangeMode = (value:boolean): void => {
    this.isTrackChanges = value;
  };

  setActiveTrackChangeId = (activeTrackChangeId: string | null): void => {
    this.activeTrackChangeId = activeTrackChangeId;
  };

  hasDifferentTrackChanges = (trackChanges: TTrackChange[][]): boolean => {
    if (this.trackChanges.length !== trackChanges.length) {
      return true;
    }
    return this.trackChanges.some((existingTrackChange) => {
      return !trackChanges.some((trackChange:TTrackChange[]) =>
        _.isEqual(existingTrackChange, trackChange)
      );
    });
  };

  setTrackChanges = (trackChanges: TTrackChange[][]): void => {
    this.trackChanges = trackChanges;
  };
}

export default new TrackChangesStore();
