export class IntervalController {
  timer: NodeJS.Timeout | null = null;
  constructor(condition: () => boolean, callback: () => any, interval = 200) {
    this.startInterval(condition, callback, interval);
  }

  startInterval(
    condition: () => boolean,
    callback: () => any,
    interval: number
  ): void {
    this.timer = setInterval(() => {
      if (condition()) {
        callback();
        this.clearSetInterval();
      }
    }, interval);
  }

  clearSetInterval(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
