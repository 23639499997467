import { db } from "../bookDb";


/*
    Collaborated: 
    When you are the collaborator of the book
*/

export const getCollaboratedCollaborationsFromIDB = () => db.collaborations.toArray().then(collections => collections.filter(collection => collection.collaborated));

export const getCollaboratedThemesFromIDB = () => db.theme.toArray().then(collections => collections.filter(collection => collection.collaborated));

export const getCollaboratedBooksFromIDB = () => db.books.toArray().then(collections => collections.filter(collection => collection.collaborated));

export const getCollaboratedUserMetasFromIDB = () => db.collaborator_metas.toArray().then(collections => collections.filter(collection => collection.collaborated));

export const SaveCollaboratedThemesToIDB = async(themes: IThemeStore.ThemeResponse[]): Promise<string> => {
  const collaborated_themes = themes.map(theme => ({
      ...theme,
      collaborated: true
  }));

  return await db.theme.bulkPut(collaborated_themes);
};

export const SaveCollaboratedBookToIDB = async (books: IBookStore.Book[]): Promise<string> => {
  const collaborated_books = books.map(book => ({
      ...book,
      collaborated: true
  }));

  return await db.books.bulkPut(collaborated_books);
};

export const SaveOneCollaboratedBookToIDB = async (book: IBookStore.Book): Promise<string> => {
  return await db.books.put({
      ...book,
      collaborated: true
  });
};


export const SaveCollaboratedCollaborationsToIDB = async (collaborations: ICollabStore.BookCollaboration[]): Promise<string> => {
  const collaborated_collaborations = collaborations.map(collaboration => ({
      ...collaboration,
      collaborated: true
  }));

  return await db.collaborations.bulkPut(collaborated_collaborations);
};

export const SaveCollaboratedUserMetasToIDB = async(collaborators: ICollabStore.AuthorMetaMap): Promise<string> => {
  const collaborated_users = Object.keys(collaborators).map(id => ({
      ...collaborators[id],
      _id: id,
      collaborated: true
  }));

  return await db.collaborator_metas.bulkPut(collaborated_users);
};


/*
    Collaboration: 
    When you are the owner of the book
*/

export const getCollaborationsFromIDB = () => db.collaborations.toArray().then(collections => collections.filter(collection => !collection.collaborated));

export const getCollaborationsUserMetasFromIDB = () => db.collaborator_metas.toArray().then(collections => collections.filter(collection => !collection.collaborated));

export const SaveCollaborationsToIDB = async (collaborations: ICollabStore.BookCollaboration[]): Promise<string> => {
  const collaborated_collaborations = collaborations.map(collaboration => ({
      ...collaboration,
  }));

  return await db.collaborations.bulkPut(collaborated_collaborations);
};

export const SaveCollaborationUserMetasToIDB= async(collaborators: ICollabStore.AuthorMetaMap): Promise<string> => {
  const collaborated_users = Object.keys(collaborators).map(id => ({
      ...collaborators[id],
      _id: id,
  }));

  return await db.collaborator_metas.bulkPut(collaborated_users);
};