import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useHistory, useLocation } from "react-router-dom";

import useRootStore from "../../store/useRootStore";

import { AtticusTab, TabProps } from "../../components/Shared/Tabs";
import {
  BookListGrid,
  BookViewSwitcher,
} from "../../components/Books";
import {
  DeleteIcon,
} from "../../content/icons";
import { NewBookModal } from "../../components/Books/forms";
import { useOnlineStatus } from "../../utils/hooks/isOffline";
import { validateSort, sortShelfItems } from "../../utils/helper";
import { EmptyShelf } from "../../components/Shared/Empty";
import { Dialog } from "../../components/Shared/Modal";
import { MenuInfo } from "rc-menu/lib/interface";
import { CollaboratedBookListView } from "./collaborated-book-list";
import { differenceBy } from "lodash";

export const CollaborationsContainer = observer(() => {
  const { push } = useHistory();
  const { search } = useLocation();
  const listing = new URLSearchParams(search).get("listing");
  const urlSort = new URLSearchParams(search).get("sort") as IShelfStore.BookSortOptionType;
  const urlSearch = new URLSearchParams(search).get("search") as string;
  const urlView = new URLSearchParams(search).get("view") as string;
  const {
    sortBy,
    searchTerm,
    view,
    newBookModal,
    setModal,
    newBook,
    setSearchTerm,
    setSortBy,
    setView
  } = useRootStore().shelfStore;
  const {
    collaborated_books,
    collaborated_books_meta,
    collaborated_books_collaborations,
    handleRemoveAccess
  } = useRootStore().collaborationStore;
  const isOnline = useOnlineStatus();

  useEffect(() => {
    setSortBy(validateSort(urlSort));
    setSearchTerm(urlSearch || "");
    setView(urlView === "grid" || urlView === "list" ? urlView : "grid");
  }, []);


  const handleTabChange = ((activeKey: string) => {
    const params = new URLSearchParams(search);
    params.set("listing", activeKey);
    const queryString = params.toString();
    push({
      search: queryString,
    });
  });

  const handleShelfButtonClick = (
    buttonType: IShelfStore.ShelfButtonItemType
  ) => {
    if (buttonType === "create-book") {
      setModal("newBookModal", true);
    }
  };

  const preventDefaultAction = (event: MenuInfo) => {
    event.domEvent.preventDefault();
    event.domEvent.stopPropagation();
    return;
  };

  const combinedItems = useMemo(() => {
    const filteredBooks = [...toJS(collaborated_books)].map((book) => ({ ...book, shelfType: "book" }));
    const filteredMetas = differenceBy([ ...toJS(collaborated_books_meta)], filteredBooks, "_id").map((book) => ({ ...book, shelfType: "meta" }));

    return [
      ...filteredMetas,
      ...filteredBooks,
    ] as IShelfStore.ShelfItemExtendedTypes[];
  }, [collaborated_books, collaborated_books_meta]);


  const { allItems, coWriteItems, editItems, commentItems } = useMemo(() => {
    const allItems: IShelfStore.ShelfItem[] = [];
    const coWriteItems: IShelfStore.ShelfItem[] = [];
    const editItems: IShelfStore.ShelfItem[] = [];
    const commentItems: IShelfStore.ShelfItem[] = [];

    sortShelfItems(combinedItems, validateSort(urlSort) || sortBy)
      .filter(
        (item) =>
          [
            item.title || "",
            item.project || "",
            ...(item.author || []),
            ...(item.versionTags || [])
          ].filter(
            (term) => term.toLowerCase().includes((urlSearch || searchTerm).toLowerCase())
          ).length > 0
      )
      .map((item) => {
        const parsedItem = {
          item,
          type: item.shelfType,
          actionItem: isOnline
            ? [
              {
                key: "remove-access",
                label: "Remove Access",
                onClick: (event) => {
                  preventDefaultAction(event);
                  handleRemoveAccess(item as IBookStore.Book);
                },
                icon: <DeleteIcon />
              },
            ] : []
        };

        allItems.push(parsedItem as IShelfStore.ShelfItem);
        collaborated_books_collaborations.map((collab_item) => {
          if (item._id === collab_item.bookId && collab_item.type === "co_author") {
            coWriteItems.push(parsedItem as IShelfStore.ShelfItem);
          }

          if (item._id === collab_item.bookId && collab_item.type === "editor") {
            editItems.push(parsedItem as IShelfStore.ShelfItem);
          }

          if (item._id === collab_item.bookId && collab_item.type === "beta_reader") {
            commentItems.push(parsedItem as IShelfStore.ShelfItem);
          }
        });
      });
    return {
      allItems,
      coWriteItems,
      editItems,
      commentItems
    };
  }, [collaborated_books, collaborated_books_meta, combinedItems, urlSort, sortBy, urlSearch, searchTerm, view, isOnline, collaborated_books_collaborations]);

  // render accordingly based on the type (Boxsets, Books and Masterpages)
  const renderList = (
    items: (IShelfStore.ShelfItem)[]
  ) =>
    items.length > 0 ? (
      view === "list" ? (
        <CollaboratedBookListView items={items} />
      ) : (
        <BookListGrid
          items={items}
          handleButtonClick={handleShelfButtonClick}
        />
      )
    ) : null;


  const listFallback = (title: string, itemLength: number) => itemLength > 0 ? null : <EmptyShelf title={title} />;

  const tabItems: TabProps[] = [
    {
      key: "all",
      label: "All",
      count: allItems.length,
      children: allItems.length > 0 ? renderList(allItems) : listFallback("No Books Yet!", allItems.length),
    },
    {
      key: "co-write",
      label: "Co-Write",
      count: coWriteItems.length,
      children: coWriteItems.length > 0 ? renderList(coWriteItems) : listFallback("No Books Yet!", coWriteItems.length),
    },
    {
      key: "track-changes",
      label: "Track Change Edits",
      count: editItems.length,
      children: editItems.length > 0 ? renderList(editItems) : listFallback("No Books Yet!", editItems.length),
    },
    {
      key: "comment",
      label: "Comments",
      count: commentItems.length,
      children: commentItems.length > 0 ? renderList(commentItems) : listFallback("No Books Yet!", commentItems.length),
    },
  ];

  return (
    <>
      <>
        <div>
          <AtticusTab
            variant="primary-blue"
            defaultActiveKey={listing || "all"}
            items={tabItems}
            tabBarExtraContent={<BookViewSwitcher />}
            onChange={handleTabChange}
          />
        </div>
        <NewBookModal
          showModal={newBookModal}
          onNewBook={async (params) => {
            await newBook(params);
            setModal("newBookModal", false);
            push(`/books/${params._id}/${params.chapterId}`);
          }}
          onClose={() => setModal("newBookModal", false)}
        />
      </>
    </>
  );
});
