import React, { useEffect, useState } from "react";
import { Modal } from "../../components/Shared/Modal";


export const CollaborationUserWarningModal = ({ open, onClose }) => {
  const LIMITED_APP_URL= process.env.REACT_APP_LIMITED_APP_URL || "";
  return (
    <Modal
      open={open}
      centered={true}
      title={<div className="warning-modal-title">Important Note</div>}
      onCancel={() => {
        onClose(false);
      }}
      destroyOnClose={true}
      rightBtn={{
        type: "at-primary",
        danger: true,
        onClick: () => {
          onClose(false);
        },
        children: "Okay",
      }}
    >
      <p>
        It looks like you’ve opened the Current Version of Atticus. To continue
        working in the Limited Version, please return to:
        <a href={LIMITED_APP_URL}>{LIMITED_APP_URL}</a>. 
        If you haven’t already, please bookmark that link for future use.
      </p>
      <br />
      <p>
        Important: Working in both versions may cause content conflicts. Please
        ensure you are only working in the Limited Version.
      </p>
    </Modal>
  );
};
