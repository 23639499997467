import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Link, Redirect } from "react-router-dom";
import mitt from "mitt";

import useRootStore from "../../store/useRootStore";

import { useOnlineStatus } from "../../utils/hooks/isOffline";

// sync
// import { syncData, SaveTemplateToDB } from "../../../utils/sync";
import { syncBookBaseData, syncBooks, syncChapterBodies, syncThemes } from "../../utils/sync";

import Header from "../header";
import PDFExporter from "../../components/Shared/PDFExporter/PDFExporter";
import { FontLoader } from "./";

import useWebsocket from "../../utils/hooks/useWebsocket";
import { db } from "../../db/bookDb";
import {
  getBookCountFromRemoteDB,
  getCollaboratedBookFromRemoteDB,
  saveBookToLocalDB,
  syncRemoteBookWithLocalDB,
} from "../../utils/sync/helper";
import { DeleteBooksFromDB, deleteChapterTemplateFromDB, GetBookFromDB } from "../../utils/offline.book.helpers";
import {
  BOOKSHELF_BOOK_ADDED,
  BOOKSHELF_BOOK_REMOVED,
  BOOKSHELF_DETAIL_UPDATE,
  BOOKSHELF_MASTERPAGE_ADDED,
  BOOKSHELF_MASTERPAGE_REMOVED,
  BOOKSHELF_REMOVE_COLLABORATION
} from "../../utils/bookshelf-ws-helper";
import { ShelfMasterPageWSMessageData, ShelfWSMessageData } from "../../types/common";
import { SyncUpdates } from "../sync";
import { Modal as AtticusModal} from "../../components/Shared/Modal";
import { CollaborationUserWarningModal } from "./CollaborationUserWarningModal";

const WithAuthComponent: React.FC = observer(({ children }) => {
    const { user, loadUserProfile, getUserProfile } = useRootStore().authStore;
    const { 
      appIdle,
      setEventEmitter, 
      getEventEmitter,
      setIdleState
    } = useRootStore().appStore;
    const { 
      loadAllBooks, 
      setBooks, 
      doInitialBooksMount, 
      setMounting,
      clearMetaBooks
    } = useRootStore().shelfStore;
    const { resetCurrentBook } = useRootStore().bookStore;
    const { loadThemes, mountPreviewDevices } = useRootStore().themeStore;
    const { getSMProfilesFromDB } = useRootStore().socialProfileStore;
    const { loadTemplates } = useRootStore().chapterStore;
    const { 
      loadUserCollaborations, 
      loadCollaborations,
      loadAllCollaboratedBooks,
      loadCollaboratedThemes,
      setMetaCollaberatedBooks, 
      setCollaberatedBooks, 
      getCollaboratedBooks, 
      addCollaberatedBook, 
      addCollaboratedBooksCollaboration, 
      removeCollaboratedBooksCollaborations, 
      removeCollaboration, 
      clearMetaCollaboratedBooks,
      getMetaCollaberatedBooks,
    } = useRootStore().collaborationStore;
    const { setSocket, socket } = useRootStore().bookSyncWebSocketStore;

    const isOnline = useOnlineStatus();
    const syncInterval = useRef<NodeJS.Timeout | null>(null);

    // Sync function that will run before on mounting the Application
    const sync = () => {
      if (isOnline) {
        // Load user profile before mount
        loadUserProfile();
        // Load books and mount it before mount
        doInitMount();
      }

      // Do rest of the local sync after inital mount
      mountLocally();

      // Clear all meta data for books after mount
      clearMetaBooks();
      clearMetaCollaboratedBooks();
    };

    //Initiate book sync and add post mount sync as callback
    const doInitBookMount = async () => {
      const { count } = await getBookCountFromRemoteDB();

      const bookIds: string[] = await doInitialBooksMount(
        count
      );
      return bookIds;
    };
    
    const doInitCollaborationMount = async () => {
      const { meta_books, collaborations } = await loadUserCollaborations();
      setMetaCollaberatedBooks(meta_books);
      return collaborations.map(c => c.bookId) || [];
    };

    const concurrentMetaBookLoad = async () => {  
      // Receive both book and collaborated book ids' 
      const results = await Promise.allSettled([
        doInitBookMount(),
        doInitCollaborationMount(),
      ]);

      // Filter for fulfilled results and flatten the values
      const [bookIds, collabBookIds] = results
      .filter((result): result is PromiseFulfilledResult<string[]> => result.status === "fulfilled")
      .map(result => result.value); // Flatten the arrays into one array of strings

      return {
        bookIds,
        collabBookIds
      };
    };

    const doInitMount = async () => {
      // Receive both book and collaborated book ids' 
      const { bookIds, collabBookIds } = await concurrentMetaBookLoad();

      // Set Unmount
      setMounting(false);

      // Start Mounting themes
      mountThemes();
      
      // Load All books with book ids
      await Promise.allSettled([
        loadAllBooks(bookIds),
        loadAllCollaboratedBooks(collabBookIds)
      ]);
      
      // Load Collaborations at the last
      await loadCollaborations();

      // Set idle state flag once everything's loaded
      setIdleState(true);
    };

    const mountThemes =  async () => {
      //Load themes
      if (isOnline) {
        await syncThemes();
        await loadCollaboratedThemes();
      }

      // Mount themes by getting from indexeddb
      loadThemes();
    };

    // Post mount sync 
    const mountLocally = async () => {
      // Mount Books mounting getting from indexeddb
      resetCurrentBook();

      // Load Templates
      loadTemplates();
      
      // Mount Epub Preview devices
      mountPreviewDevices();
      
      // load user's social media profiles in the app state
      getSMProfilesFromDB();
    };

    useEffect(() => {
        sync();
    }, []);

    const webSocketUrl = process.env.REACT_APP_SYNC_URL || "";
    const socketConnection = useWebsocket(
      webSocketUrl ? `${webSocketUrl}` : ""
    );
    setSocket(socketConnection);

    const handleShelfBookAdded = async (data: ShelfWSMessageData) => {
      const { bookId, isCollabBook } = data;
      if (isCollabBook && bookId) {
        const {book, collaborations: newCollaborations} = await getCollaboratedBookFromRemoteDB(bookId);
        
        if (book) {
          const newCollabBook = {
            ...book,
            collaborated: true,
          };

          saveBookToLocalDB(newCollabBook);
          addCollaboratedBooksCollaboration([newCollaborations]);
          addCollaberatedBook([newCollabBook]);
        }
      } else {
        if (bookId)
          await syncRemoteBookWithLocalDB(bookId).then(async () => {
            const booksFromDb = await db.books.toArray();
            setBooks(booksFromDb);
          });
      }
    };

    const handleShelfBookRemoved = async (data: ShelfWSMessageData) => {
      const { bookId, isCollabBook } = data;
      if (bookId)
        await DeleteBooksFromDB([bookId]).then(async () => {
          const books = await db.books.toArray();
          if (isCollabBook) {
            const collaborated_books = books.filter(
              (book) => book.collaborated
            );
            setCollaberatedBooks(collaborated_books);
            removeCollaboratedBooksCollaborations(bookId);
          } else {
            const filteredBooks = books.filter((book) => !book.collaborated);
            setBooks(filteredBooks);
          }
        });
    };
    
    const handleShelfBookDetailsUpdate = async (data: ShelfWSMessageData) => {
      const { bookId } = data;

      if (!bookId) return;

      const booksFromDb = await db.books.toArray();
      const book = booksFromDb.find((book) => book._id === bookId);

      if (!book) return;

      const isCollabBook = book.collaborated;

      await syncRemoteBookWithLocalDB(bookId, isCollabBook).then(async () => {
        if (isCollabBook) {
          //getUpdated book
          const shelfBook = await GetBookFromDB(bookId);
          const collaborated_books = await getCollaboratedBooks();

          const updatedCollaboratedBooks = collaborated_books.map((book) =>
            book._id === bookId ? { ...book, ...shelfBook } : book
          );

          setCollaberatedBooks(updatedCollaboratedBooks);
        } else {
          const updatedBooks = await db.books.toArray();
          setBooks(updatedBooks);
        }
      });
    };
  

    const handleShelfMasterPageAdded = async () => {
      loadTemplates();
    };

    const handleCollaborationRemoved = async (data: any) => {
      const { collaboration_id = "" }  = data;

      if(collaboration_id) {
        await removeCollaboration(collaboration_id);
      }
    };

    const handleShelfMasterPageRemoved = async (
      data: ShelfMasterPageWSMessageData
    ) => {
      const { templateId } = data;
      await deleteChapterTemplateFromDB(templateId).then(() => loadTemplates());
    };

    const handleShelfBookDelete = async (bookId: string) => {
      const collabBooks = getCollaboratedBooks();
      const isCollaborator = collabBooks.some((book) => book._id === bookId);
      if (isCollaborator) {
        await DeleteBooksFromDB([bookId]).then(() => {
          const collaboratedBooks = collabBooks.filter(
            (book) => book._id !== bookId
          );
          const metaCollaboratedBooks = getMetaCollaberatedBooks().filter(
            (bookMeta) => bookMeta._id !== bookId
          );
          setCollaberatedBooks(collaboratedBooks);
          setMetaCollaberatedBooks(metaCollaboratedBooks);
        });
      }
    };

    const handleRecivedUpdate = async (event) => {
      const message = JSON.parse(event.data);
      const { userId } = message.data;
      const userProfile = user || (await getUserProfile());
      if (userProfile?._id === userId) {
        switch (message.type) {
          case BOOKSHELF_BOOK_ADDED:
            await handleShelfBookAdded(message.data);
            break;
          case BOOKSHELF_BOOK_REMOVED:
            await handleShelfBookRemoved(message.data);
            break;
          case BOOKSHELF_MASTERPAGE_ADDED:
            await handleShelfMasterPageAdded();
            break;
          case BOOKSHELF_MASTERPAGE_REMOVED:
            await handleShelfMasterPageRemoved(message.data);
            break;
        }
      }

      if(message.type === BOOKSHELF_DETAIL_UPDATE){
        await handleShelfBookDetailsUpdate(message.data); 
      }
      
      if(message.type === BOOKSHELF_REMOVE_COLLABORATION){
        await handleCollaborationRemoved(message.data);
      }

      // handle bookshelf sync for all collaborators of the book when book is deleted from author's end
      if(message.type === BOOKSHELF_BOOK_REMOVED){
        handleShelfBookDelete(message.data.bookId);
      }
    };

    useEffect(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.addEventListener("message", handleRecivedUpdate);
      }
      // Clean up the event listener when the component unmounts
      return () => {
        if (socket) {
          socket.removeEventListener("message", handleRecivedUpdate);
        }
      };
    }, [socket]);

    useEffect(() => {
      // close the socket.
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }, []);


    /** Periodical sync useEffect */
    useEffect(() => {
      const startPeriodicSync = () => {
        if (syncInterval.current) {
          clearInterval(syncInterval.current);
        }
        syncInterval.current = setInterval(async () => {
          if (isOnline) {
            await syncBooks();
            await syncChapterBodies();
            await syncThemes();
          }
        }, 6 * 60 * 60 * 1000); // 6 hours in milliseconds
      };

      startPeriodicSync();

      return () => {
        if (syncInterval.current) {
          clearInterval(syncInterval.current);
        }
      };

    }, [isOnline]);

    const syncWhenGoOnline =  async () => {
      if (isOnline && appIdle) {
        try {
          await Promise.all([
            syncBooks(),
            syncChapterBodies(),
            syncThemes()
          ]);
        } catch (error) {
          console.error("Sync failed after returning online");
        }
      }
    };
  
    useEffect(() => {
      syncWhenGoOnline();
    }, [isOnline]);
    

    useEffect(() => {
      const appEventEmitter = mitt<IAppEvents.AppEvents>();
      setEventEmitter(appEventEmitter);
      return () => {
          getEventEmitter()?.all.clear();
      };
    }, [getEventEmitter, setEventEmitter]);

    return (
        <div>
            <SyncUpdates />
            <FontLoader />
            <Header />
            <div className="main">
                {children}
            </div>
            <PDFExporter />
        </div>
    );
});

const LayoutWithAuth : React.FC = (props) => {
  const { token, user } = useRootStore().authStore;

  const [openClassicUserWarning, setOpenClassicUserWarning] = useState<boolean>(false);

  useEffect(() => {
    const handleInvalidToken = e => {
      // check if auth token does not have value
      if (e.key === "atticus-auth-token" && !e.newValue) {
        // reload page to redirect
        window.location.reload();
      }
    };

    // apply listener for storage changes
    window.addEventListener("storage", handleInvalidToken);

    return () => {
      // remove local storage change listener
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  useEffect(() => {
    const isClassicUserLocal = localStorage.getItem("isClassicUser");
    if (
      (isClassicUserLocal !== null && JSON.parse(isClassicUserLocal)) ||
      user?.isAtticusClassicUser
    )
      setOpenClassicUserWarning(true);
  }, [user]);

  const handleCloseWarning = () => {
    setOpenClassicUserWarning(false);
  };

  return token ? (
    <>
      <CollaborationUserWarningModal 
        open={openClassicUserWarning}
        onClose={handleCloseWarning}/>
      <WithAuthComponent {...props} />
    </>
  ) : (
    <Redirect to="/auth/sign-out" />
  );
};

export const WithAuth = observer(LayoutWithAuth);

