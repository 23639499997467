import React from "react";
import { Menu, Dropdown, Button, Modal } from "antd";
import { MoreIcon } from "../../content/icons";
//store
import useRootStore from "../../store/useRootStore";

import { useOnlineStatus } from "../../utils/hooks/isOffline";

import { CheckOutlined } from "@ant-design/icons";

import { observer } from "mobx-react";

const { confirm } = Modal;
const { SubMenu } = Menu;

const BodyDropdown = observer(() => {
	const { allBody, setAllBody, saveChapterMetaUpdatesAsBulk, setBook, book} = useRootStore().bookStore;
	const { refreshCache } = useRootStore().pdfCacheStore;
  const {numbered, beginOn} = allBody;
  const isOnline = useOnlineStatus();

  async function invokeConfirm(callback: Promise<void>) {
      return await confirm({
        icon: null,
        title: <h2 className="section-heading">Update all body chapters</h2>,
        content: "Are you sure you want to apply this change to all body chapters, in doing so will remove reset previously made changes?",
        centered: true,
        okType: "danger",
        okText: "Confirm",
        okButtonProps: {
          type: "primary",
          danger: true,
          style: {
            flex: 1,
          },
        },
        onOk: () => {
          callback;
        },
        cancelButtonProps: {
          className: "btn-a",
          style: {
            flex: 1,
          },
        },
        cancelText: "Cancel",
      });
  }

  const updateStartOn = async (side: "any" | "left" | "right") => {
    const chaptersToUpdate = book.chapters
        .filter((chapter, i) => i > 0 && chapter.startOn !== side)
        .map(chapter => ({ ...chapter, startOn: side }));

    if (chaptersToUpdate.length > 0) {
        await saveChapterMetaUpdatesAsBulk(chaptersToUpdate, false, false);
    }

    const updatedChapters = book.chapters.map((chapter, i) =>
        i > 0 && chapter.startOn !== side ? { ...chapter, startOn: side } : chapter
    );

    setBook({ ...book, chapters: updatedChapters });

    setAllBody({
        numbered: allBody.numbered,
        beginOn: side,
        active: true,
    });

    const chapterData = updatedChapters.map(({ _id, type, startOn }) => ({
        chapterId: _id,
        chapterType: type,
        startOn,
    }) as IPDFCacheStore.ChapterCacheMetaData);

    refreshCache(book._id, "chapter-list-properties-change", {
        "chapter-list-properties-change": { chapters: chapterData },
    });
  };


  const updateNumbered = async (n: boolean) => {
    const chaptersToUpdate = book.chapters
        .filter(chapter => chapter.numbered !== n)
        .map(chapter => ({ ...chapter, numbered: n }));

    if (chaptersToUpdate.length > 0) {
        await saveChapterMetaUpdatesAsBulk(chaptersToUpdate, false, false);
    }

    const updatedChapters = book.chapters.map(chapter => 
        chapter.numbered !== n ? { ...chapter, numbered: n } : chapter
    );

    setBook({ ...book, chapters: updatedChapters });
    setAllBody({ numbered: n, beginOn, active: true });

    const chapterData = updatedChapters.map(({ _id, type, startOn }) => ({
        chapterId: _id,
        chapterType: type,
        startOn,
    }) as IPDFCacheStore.ChapterCacheMetaData);

    refreshCache(book._id, "chapter-list-properties-change", {
        "chapter-list-properties-change": { chapters: chapterData },
    });
  };


  const chps =  book.chapters
    .filter((_, i) => i > 0)
    .map(d => ({
      ...d, 
      startOn: d.startOn || "any", 
    }));
  
  return isOnline ? (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu>  
            <SubMenu title="Begin On">
              <Menu.Item
                icon={chps.every((d) => d.startOn === "any") ? <CheckOutlined /> : null}
                onClick={() => { updateStartOn("any"); }}
              >
                Either side
              </Menu.Item>
              <Menu.Item
                icon={chps.every((d) => d.startOn === "right") ? <CheckOutlined /> : null}
                onClick={() => { updateStartOn("right"); }}
              >
                Right side
              </Menu.Item>
              <Menu.Item
                icon={chps.every((d) => d.startOn === "left")? <CheckOutlined /> : null}
                onClick={() => { updateStartOn("left"); }}
              >
                Left side
              </Menu.Item>
            </SubMenu>          
            <Menu.Item
              icon={book.chapters.every((d) => d.numbered !== undefined ? d.numbered : d.type === "chapter" ? true : false) ? <CheckOutlined /> : null}
              onClick={() => {
                updateNumbered(!numbered);
              }}
            >
              Numbered chapter
            </Menu.Item>
        </Menu>
      }
    >
      <Button type="text" shape="round" size="small" className="all-body-button"><span className="chapter-block-icon"><MoreIcon /></span></Button>
    </Dropdown>
  ) : null;
});

export default BodyDropdown;