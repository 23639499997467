import * as Y from "yjs";
import { IDBInstace } from "@surge-global-engineering/y-indexeddb";

import { syncChapterWithRemote } from "../y";
import { getChapterIdsToSync, getBatchedOperations, getBookChapterIdsToSync } from "./helper";

/**
 * Sync client-side IndexedDb and server-side MongoDB for a chapter manually using HTTP instead of WS
 * @param chapterId chapter id
 */
export const syncChapterBody = async (chapterId: string): Promise<void> => {
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  const ydoc = new Y.Doc();
  await idbInstance.syncUpdatesFromDBToDoc(ydoc);
  const clientState = Y.encodeStateAsUpdate(ydoc);
  ydoc.destroy();
  const syncResponse = await syncChapterWithRemote(chapterId, clientState);
  await idbInstance.updateDB(syncResponse.serverDiff);
  idbInstance.closeConnection();
  console.log("syncing completed --", chapterId);
};

export const syncChapterBodies = async(): Promise<void> => {
  const allPromises: Promise<void>[] = [];
  const chapterIdsToSync = await getChapterIdsToSync();
  const batchedChaptersToSync = getBatchedOperations(20, chapterIdsToSync);
  for(const batch of batchedChaptersToSync){
    for(const chapterId of batch){
      allPromises.push(syncChapterBody(chapterId));
    }
    await Promise.all(allPromises); 
  }
};

/** Sync chapter bodies for a single book. */
export const syncBookChapterBodies = async(bookId:string): Promise<void> => {
  const allPromises: Promise<void>[] = [];
  const chapterIdsToSync = await getBookChapterIdsToSync(bookId);
  const batchedChaptersToSync = getBatchedOperations(20, chapterIdsToSync);
  for(const batch of batchedChaptersToSync){
    for(const chapterId of batch){
      allPromises.push(syncChapterBody(chapterId));
    }
    await Promise.all(allPromises); 
  }
};