import React, { useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { observer } from "mobx-react";

//hooks
import useRootStore from "../../../store/useRootStore";
import { useSideBarActions } from "../../../utils/hooks/useSideBarActions";

//partials
import { SideBarItem } from "../../../types/sidebar";
import {
  ChapterItem,
  SceneItem,
  ListPlaceholder,
  BodySeperator,
} from "./items";

//helpers
import { nonDraggableChapterTypes } from "../helpers";
import { can } from "../../casl/helpers";

interface ListItemProps {
  item: SideBarItem;
  index: number;
  droppableState: DroppableStateSnapshot;
}

const Chapters = observer(() => {
  const { book } = useRootStore().bookStore;
  const { chapterMeta } = useRootStore().chapterStore;
  const { selectedChaptersMap, expandedChapters, sceneCacheUUID } =
    useRootStore().sideMenuStore;

  const isMultiSelected = Object.keys(selectedChaptersMap).length > 1;

  const { onDragEnd, sideBarItems, refreshSidebar } = useSideBarActions();

  const RowItem = function Row({ item, index, droppableState }: ListItemProps) {
    const draggableDisabled = !can("view", "drag-and-drop");

    if (item.type === "chapter") {
      return (
        <Draggable
          isDragDisabled={ draggableDisabled || nonDraggableChapterTypes.includes(item.chapter.type) || isMultiSelected } // disable draggable for 'nonDraggableChapterTypes' chapter types  ot if multiple chapters are selected
          draggableId={item.id}
          index={index}
          key={item.id}
        >
          {(provided, snapshot) => (
            <ChapterItem
              key={item.id}
              item={item}
              snapshot={snapshot}
              provided={provided}
            />
          )}
        </Draggable>
      );
    }

    if (item.type === "scene") {
      return (
        <Draggable
          isDragDisabled={draggableDisabled}
          draggableId={item.id}
          index={index}
          key={item.id}
        >
          {(provided, snapshot) => (
            <SceneItem
              key={item.id}
              item={item}
              snapshot={snapshot}
              provided={provided}
            />
          )}
        </Draggable>
      );
    }

    if (item.type === "body-separator") {
      return (
        <Draggable
          isDragDisabled={true}
          draggableId={item.id}
          index={index}
          key={item.id}
        >
          {(provided, snapshot) => (
            <BodySeperator
              key={item.id}
              item={item}
              snapshot={snapshot}
              provided={provided}
            />
          )}
        </Draggable>
      );
    }

    if (item.type === "placeholder") {
      return (
        <Draggable
          isDragDisabled={true}
          draggableId={item.id}
          index={index}
          key={item.id}
        >
          {(provided, snapshot) => (
            <ListPlaceholder
              key={item.id}
              item={item}
              isVisible={droppableState.isDraggingOver}
              provided={provided}
            />
          )}
        </Draggable>
      );
    }

    return null;
  };

  useEffect(
    () => {
      refreshSidebar(book);
    },
    // [book, sceneCacheMap, expandedChapters, sceneIndex, chapter]
    [book, expandedChapters, chapterMeta, sceneCacheUUID]
  );

  return sideBarItems && sideBarItems.length > 0 ? (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sideBarItems.map((item, index) => (
                <RowItem
                  droppableState={snapshot}
                  item={item}
                  index={index}
                  key={index}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  ) : null;
});

export default Chapters;
